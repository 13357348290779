import React, { useEffect, useState } from 'react';
import Tabs from '../../Components/Tab/SettingsTab';
import PersonalInformation from '../../Components/Settings/PersonalInformation';
import NextOfKin from '../../Components/Settings/NextOfKin';
import BankAndCard from '../../Components/Settings/BankandCard';
import ChangePassword from '../../Components/Settings/ChangePassword';
import { useLocation, useNavigate } from 'react-router-dom';

function Setting() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    { id: 'personal', label: 'Personal Information' },
    { id: 'nextofkin', label: 'Next Of Kin' },
    { id: 'changepassword', label: 'Change Password' },
    { id: 'bankaccount', label: 'Account' },

  ];
  const history = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get('tab');
  console.log(tab);
  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);
    console.log(`Selected Tab: ${tabs[index].id}`);
    const tabLabel = tabs[index].id;
    history(`/app/setting?tab=${encodeURIComponent(tabLabel)}`);

    console.log(`Selected Tab: ${tabLabel}`);
  };
  useEffect(() => {
    console.log(tab);
    if (tab === 'personal' || tab === null) {
      console.log('Condition met: Setting active tab index to 0');
      setActiveTabIndex(0);
    }
    if (tab === 'nextofkin') {
      setActiveTabIndex(1);
    }
    if (tab === 'changepassword') {
      setActiveTabIndex(2);
    }
    if (tab === 'bankaccount') {
      setActiveTabIndex(3);
    }
  }, [setActiveTabIndex, tab]);
  return (
    <div className='p-2 md:p-10 md:w-10/12 lg:w-7/12 '>
      <div className="container">
        <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange}/>
      </div>
      <div className=" w-full">
        <div className="">
          {activeTabIndex === 0 ?
            <PersonalInformation/>
            : activeTabIndex === 1 ?
              <NextOfKin />
              : activeTabIndex === 2 ?
                <ChangePassword />
                : activeTabIndex === 3 ?
                          <BankAndCard />
                  : null
          }
        </div>
      </div>
    </div>
  );
}

export default Setting;
