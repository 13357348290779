import { createAction } from '@reduxjs/toolkit';
import { ForgotPassword, Login, Register } from '../Services/constant/serviceType';
import { NavigateFunction } from 'react-router-dom';
import { User } from './constants/actionTypes';

export const handleLoginRequest = createAction<{
  payload: Login,
  navigate: NavigateFunction
}>('LOGIN_REQUEST');
export const handleLoginSuccess = createAction<{ token: string }>('LOGIN_SUCCESS');
export const handleLoginFailure = createAction<string>('LOGIN_FAILURE');
export const handleLogoutRequest = createAction('REQUEST_LOGOUT');
export const handleLogout = createAction('LOGOUT');
export const handleRegisterRequest = createAction<{
  payload: Register,
  navigate: NavigateFunction
}>('REGISTER_REQUEST');
export const handleRegisterSuccess = createAction<{ token: string }>('REGISTER_SUCCESS');
export const handleSuccess = createAction('SUCCESS');
export const handleStart = createAction('START');
export const handleFailure = createAction('FAILURE');
export const handleForgotPassword = createAction<{ ForgotPassword: ForgotPassword; navigate: () => void }>('FORGOT_PASSWORD');


export const handleSaveUserData = createAction<User>('SAVE_USER_DATA');
export const handlePageLoading = createAction<boolean>('PAGE_LOADER');
export const handleSaveWalletData = createAction<boolean>('SAVE_WALLET_DATA');
export const handleSaveBank = createAction<boolean>('SAVE_BANK');

