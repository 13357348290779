import { Add, Filter, SearchNormal1 } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

function ListerPortfolio() {
  const TableHeader = [
    'Property', 'Type', 'Status', 'Address',
  ];
  const TableBody = [
    {
      'Property': 'Quad in Minneapolis, Minnesota, 55405',
      'Type': 'Residential',
      'Status': 'Active',
      'Address': '26, Ogunleye Street Surulere Lagos Nigeria ',
      'Action': 'View',
    },
    {
      'Property': 'Quad in Minneapolis, Minnesota, 55405',
      'Type': 'Residential',
      'Status': 'Pending',
      'Address': '26, Ogunleye Street Surulere Lagos Nigeria ',
      'Action': 'View',
    },
    {
      'Property': 'Quad in Minneapolis, Minnesota, 55405',
      'Type': 'Residential',
      'Status': 'Failed',
      'Address': '26, Ogunleye Street Surulere Lagos Nigeria ',
      'Action': 'View',
    },
    {
      'Property': 'Quad in Minneapolis, Minnesota, 55405',
      'Type': 'Commercial',
      'Status': 'Active',
      'Address': '26, Ogunleye Street Surulere Lagos Nigeria ',
      'Action': 'View',
    },
    {
      'Property': 'Quad in Minneapolis, Minnesota, 55405',
      'Type': 'Commercial',
      'Status': 'Active',
      'Address': '26, Ogunleye Street Surulere Lagos Nigeria',
      'Action': 'View',
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className='py-9 w-11/12 mx-auto'>
        <div className={'flex justify-between items-center pb-8'}>
          <h1 className={'font-bold'}>
                        Property
          </h1>
          <button onClick={() => navigate('/app/addproperty')} className={'flex items-center bg-blue-600 rounded text-sm text-white py-1  px-2'}>
            <Add color={'#ffffff'} />
                        Add Property
          </button>
        </div>
        <div className='rounded bg-white items-center py-4 lg:px-16 px-8 flex justify-between '>
          <div className={'flex border rounded border-custom items-center p-1.5 w-4/12'}>
            <SearchNormal1 size={20} color={'#9599AD'} />
            <input type={'text'} className={'focus-visible:outline-0 text-sm text-custom w-full py-1 px-4'} />
          </div>
          <div className={'flex gap-3.5'}>
            <button
              className={'bg-red-600 text-sm text-white flex items-center gap-3 p-2 rounded                                                                                                                                                                                                                                                                                                                  '}>
              <Filter size={20} color={'#ffffff'}/>
                            Filter
            </button>
            <button
              className={'bg-green-600 text-sm text-white flex items-center gap-3 p-2 rounded                                                                                                                                                                                                                                                                                                                  '}>
              <Filter size={20} color={'#ffffff'}/>
                            Export in CSV
            </button>
          </div>
        </div>
        <div className={'rounded bg-white mt-10'} style={{ boxShadow: '0px 1px 2px 0px #38414A26' }}>
          <table className={'w-full'}>
            <thead className={'bg-[#F3F6F9]'}>
              <tr>
                {/*<th colSpan={2}>*/}
                {/*  <span>*/}
                {/*    <input type={'checkbox'} className={'rounded border border-custom'}/>*/}
                {/*  </span>*/}
                {/*</th>*/}
                <th></th>
                <th></th>
                {
                  TableHeader.map((header, index) => (
                    <th key={index} className={`py-5 text-left text-sm ${index === 0 ? 'px-7' : ''} `}>
                      {header}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                TableBody.map((body, index) => (
                  <tr key={index} className={'border-b border-custom py-4'}>
                    {/*<td colSpan={2} className={'text-center w-1/12'}>*/}
                    {/*  <span>*/}
                    {/*    <input type={'checkbox'} className={'rounded border border-custom'}/>*/}
                    {/*  </span>*/}
                    {/*</td>*/}
                    <td className={'text-sm py-4'}></td>
                    <td className={'text-sm py-4'}></td>
                    <td className={'text-sm py-4 px-7 '}>{body.Property}</td>
                    <td className={'text-sm py-4'}>{body.Type}</td>
                    <td className={'text-sm py-4'}><span className={` py-1 px-2.5 rounded text-sm ${body.Status === 'Active' ? 'bg-green-200 text-green-900' : body.Status === 'Pending' ? 'bg-yellow-100 text-yellow-700' : 'bg-red-200 text-red-900'}`}>{body.Status}</span></td>
                    <td className={'text-sm py-4'}>{body.Address}</td>
                    {/*<td className={'text-sm py-4'}>*/}
                    {/*  <div className={'flex gap-1'}>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={16} height={16}*/}
                    {/*      viewBox="0 0 24 24"*/}
                    {/*      strokeWidth={1.5} stroke="currentColor" className="size-4">*/}
                    {/*      <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"/>*/}
                    {/*    </svg>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={16} height={16}*/}
                    {/*      viewBox="0 0 24 24"*/}
                    {/*      strokeWidth={1.5} stroke="currentColor" className="size-4">*/}
                    {/*      <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>*/}
                    {/*    </svg>*/}

                    {/*  </div>*/}
                    {/*</td>*/}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ListerPortfolio;
