import { Icon } from '../../Services/constant/serviceType';

export const AllPropertyIcon = ({ size, color }: Icon) => {
  return (
      <>
          <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.1 10.8H0.9V16.2C0.9 16.6774 1.08964 17.1352 1.42721 17.4728C1.76477 17.8104 2.22261 18 2.7 18H15.3C15.7774 18 16.2352 17.8104 16.5728 17.4728C16.9104 17.1352 17.1 16.6774 17.1 16.2V10.8H9.9V12.6H8.1V10.8ZM8.1 9.9H0V4.5C0 3.51 0.81 2.7 1.8 2.7H5.4V1.8C5.4 1.32261 5.58964 0.864773 5.92721 0.527208C6.26477 0.189642 6.72261 0 7.2 0L10.8 0C11.2774 0 11.7352 0.189642 12.0728 0.527208C12.4104 0.864773 12.6 1.32261 12.6 1.8V2.7H16.2C16.6774 2.7 17.1352 2.88964 17.4728 3.22721C17.8104 3.56477 18 4.02261 18 4.5V9.9H9.9V8.1H8.1V9.9ZM10.8 2.7V1.8H7.2V2.7H10.8Z"
                  fill={color}/>
          </svg>


      </>
  );
};
