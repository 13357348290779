import { getSingleProperty } from '../services/userService';
import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';

export const useSingleProperty = ( ) => {
  const [propertyDetails, setPropertyDetails] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);
  const didFetch = useRef<boolean>(false);
  const getSinglePropertyData = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getSingleProperty(id);
      console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setPropertyDetails(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  return { getSinglePropertyData, loading, propertyDetails };
};
