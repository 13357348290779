import { authorized, unauthorized } from '../axios';
import {
  CHANGE_PASSWORD,
  CONTACT_US, FILTER_PROPERTY,
  GET_DASHBOARD,
  GET_HOME_PROPERTY,
  GET_PROPERTY,
  GET_USER_PROPERTY,
  NEXT_OF_KIN, RESET_PASSWORD,
  USER_DATA, VERIFY_OTP,
} from '../service-routes';
import {
  ChangePassword,
  ContactUs,
  CoOwnData,
  NextOfKin,
  ResetPassword,
  UserData,
  VerifyPasswordOtp,
} from '../constant/serviceType';

export const getDashboard = (id: string | number) => {
  return unauthorized.get(`${GET_DASHBOARD}/${id}`);
};

export const getProperty = (data: CoOwnData) => {
  return authorized.get(GET_PROPERTY, {
    params: data,
  });
};

export const getSingleProperty = (id: string | number) => {
  return authorized.get(`${GET_PROPERTY}/${id}`);
};

export const postNextOfKin = (payload: NextOfKin) => {
  return authorized.post(NEXT_OF_KIN, payload);
};

export const getNextOfKin = (id: string | number) => {
  return authorized.get( `${NEXT_OF_KIN}/${id}`);
};

export const updateNextOfKin = (payload: NextOfKin) => {
  return authorized.put(NEXT_OF_KIN, payload);
};

export const postUserData = (payload: UserData, id: string | number) => {
  return authorized.post(`${USER_DATA}/${id}`, payload);
};

export const getUserData = (id: string | number) => {
  return authorized.get( `${USER_DATA}/${id}`);
};

export const postContactUs = (payload: ContactUs) => {
  return authorized.post(CONTACT_US, payload);
};

export const getFilteredData = (filter: any) => {
  return authorized.get(FILTER_PROPERTY, { params: filter });
};

export const getFeaturedProperty = (data: CoOwnData) => {
  return authorized.get(GET_HOME_PROPERTY, {
    params: data,
  });
};

export const getUserProperty = (id: string | number) => {
  return authorized.get(`${GET_USER_PROPERTY}/${id}`);
};

export const postChangePassword = (id: string | number, payload: ChangePassword ) => {
  return authorized.post( `${CHANGE_PASSWORD}/${id}`, payload);
};

export const postVerifyPasswordOtp = ( payload: VerifyPasswordOtp ) => {
  return authorized.post( `${VERIFY_OTP}`, payload);
};


export const postResetPassword = (payload: ResetPassword ) => {
  return authorized.post( `${RESET_PASSWORD}`, payload);
};


