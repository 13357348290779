import React from 'react';
import '../../Stylesheets/loader.css';
interface CustomButtonProps {
  isLoading: boolean;
  onClick?: () => void;
  children: string;
  className: string;
  disabled: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ isLoading, onClick, children, className, disabled }) => {
  const disabledClass = disabled || isLoading ? 'bg-gray-300 text-black cursor-not-allowed' : '';
  return (
    <button
      onClick={onClick}
      className={`${className} flex items-center justify-center ${disabledClass}`}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default CustomButton;
