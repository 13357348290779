import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import WaitlistImage from '../../Assets/Images/WaitlistImage.png';

interface Props {
  isOpen: boolean;
  onHide: () => void;
  src: string;
  verify: () => void;
}

function PaymentFrame({ isOpen, onHide, src, verify }: Props) {
  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${WaitlistImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const iframeRef = useRef<HTMLIFrameElement |  any>(null);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const handleVerify = () => {
    if (iframeRef.current) {
      iframeRef.current.style.display = 'none';
    }
    verify();
    onHide();
  };
  console.log(isLoading);
  useEffect(() => {
    if (isOpen) {
      const id = setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.style.display = 'none';
        }
        onHide(); // Close the modal after hiding the iframe
      }, 600000); // 60,0000ms = 6 minutes
      setTimerId(id);
    }
    return () => {
      // Clear the timer if the modal is closed manually before the timer ends
      if (timerId) {
        clearTimeout(timerId);
        setTimerId(null);
      }
    };
  }, [isOpen]);
  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const handleLoad = () => {
        setIsLoading(false);
        console.log('Iframe loaded successfully.');
      };

      const handleError = () => {
        setIsLoading(false);
        console.log('Error loading iframe.');
      };

      // Attach event listeners
      iframe.addEventListener('load', handleLoad);
      iframe.addEventListener('error', handleError);

      return () => {
        // Clean up event listeners on unmount
        iframe.removeEventListener('load', handleLoad);
        iframe.removeEventListener('error', handleError);
      };
    }
  }, []);
  return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onHide}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/90" style={containerStyle} />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full transform overflow-hidden border-wait-list border-4 lg:max-w-fit rounded-2xl bg-white relative text-left align-middle shadow-xl transition-all">

                                    {/*<div className="  flex items-center justify-center bg-black opacity-90">*/}
                                    {/*    <p className="text-center text-white font-bold">Loading...</p>*/}
                                    {/*</div>*/}

                                <iframe
                                    ref={iframeRef}
                                    src={src}
                                    title="Loaded Content"
                                    width="600"
                                    height="500"
                                />
                                <div className="mt-4 text-center absolute bottom-1 right-0 w-8/12 flex justify-center p-4 pb-0">
                                    <button
                                        onClick={handleVerify}
                                        className="underline text-green-900 font-bold text-sm py-1.5 px-4 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
  );
}

export default PaymentFrame;
