import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Layout from './Components/Dashboard/Layout';
import HomeLayout from './Components/Layout/Layout';
import { ProtectedRoutes } from './Services/hooks/ProtectedRoutes';
import { ToastProvider } from './Store/NotificationContext';

function App() {
  // console.log = function () {};
  return (
    <div className="App">
      <BrowserRouter >
        <ToastProvider>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path='/app/*' element={<Layout />} />
            </Route>
            <Route path='*' element={<HomeLayout />} />
          </Routes>
        </ToastProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
