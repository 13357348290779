import React, { useState } from 'react';
import { ReactComponent as EmailIcon } from '../../Assets/Icons/Emailiconwhite.svg';
import { ReactComponent as PasswordIcon } from '../../Assets/Icons/passwordicon.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as HomeAbstract } from '../../Assets/Images/homeabstract.svg';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoginRequest } from '../../Storeredux/actions';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { Auth } from '../../Storeredux/constants/actionTypes';
import CustomButton from '../Form/CustomButton';
import { areAllKeysFilled } from '../../Utils/formValidator';
import { Eye, EyeSlash } from 'iconsax-react';

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthState = useSelector((state: RootState) => state.auth as Auth);
  const { loading } = AuthState;
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const isFormComplete = areAllKeysFilled(data);
  const togglePasswordVisibility = () => {
    setPasswordVisible(prevState => !prevState);
  };
  const submitLogin = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    console.log(data);
    dispatch(handleLoginRequest({ payload: data, navigate: navigate }));
  };
  return (
    <div>
      <div className='relative overflow-hidden flex flex-col ' style={{ height: '90vh' }}>
        <div className='w-full hidden md:flex'>
          <HomeAbstract className='abstract'/>
        </div>
        <div className=' absolute top-0 h-screen flex flex-col  items-center w-full' style={{ backgroundColor: '#0000000D' }}>
          <div className='bg-white rounded-xl w-11/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-hidden border border-input-color my-12'
            style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
            <form className='w-full p-7' onSubmit={submitLogin}>
              <div className='flex justify-between'>
                <h3 className='font-semibold text-xl'>
                                    Login
                </h3>
              </div>
              <div className='pt-6 pb-4'>
                <label className='font-semibold text-sm pb-2'>
                                    Email Address
                </label>
                <div className="relative">
                  <EmailIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input required onChange={(e) => setData({ ...data, email: e.target.value })}
                    className="text-xs focus-visible:outline-0 w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="text" placeholder="Enter your email"/>
                </div>
              </div>
              <div className=''>
                <label className='font-semibold text-sm pb-2'>
                                    Password
                </label>
                <div className="relative">
                  <PasswordIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input required onChange={(e) => setData({ ...data, password: e.target.value })}
                         className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                         type={isPasswordVisible ? 'text' : 'password'} placeholder="Enter your password"/>
                  <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  >
                    {isPasswordVisible ? <Eye size="20" color="#8E8E93"/> : <EyeSlash size="20" color="#8E8E93"/>}
                  </span>
                </div>
              </div>
              <div className='text-end text-custom font-semibold cursor-pointer py-1 text-xs'>
                <Link to='/forgotpassword'>Forgot Password?</Link>
              </div>
              {/*<div className='pb-4'>*/}
              {/*    {error && <p className='text-red-500 text-xs italic'>{error}</p>}*/}
              {/*</div>*/}
              <div className='flex justify-center'>
                <CustomButton
                  isLoading={loading}
                  disabled={!isFormComplete}
                  children={'Login'}
                  className='text-sm md:text-md w-1/2 pt-3 mt-3.5 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'
                />
              </div>
              <div className='text-center py-3 text-xs'>
                <p>Don't have an account?
                  <Link to='/register' className='font-semibold text-custom-green'> Register</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
