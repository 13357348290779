import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className='bg-white w-'>
      <div className='lg:w-11/12 mx-auto'>
        <p className='text-sm p-4'>{currentYear} © kekere.</p>
      </div>
    </div>
  );
}

export default Footer;
