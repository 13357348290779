import React, { useRef } from 'react';
import { ReactComponent as Logo } from '../../Assets/Icons/Logo.svg';
import { ReactComponent as Logout } from '../../Assets/Icons/exit.svg';
import { Link, useLocation } from 'react-router-dom';
import { handleLogoutRequest } from '../../Storeredux/actions';
import { useDispatch } from 'react-redux';
import { AllPropertyIcon } from '../../Assets/Icons/allproperties';
import { MyPropertyIcon } from '../../Assets/Icons/myProperties';
import { MyWalletIcon } from '../../Assets/Icons/myWallet';
import { SettingsIcon } from '../../Assets/Icons/settings';

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  let pageName = location.pathname;
  pageName = pageName.slice(5);

  const Dashboard = [
    {
      Name: 'All Properties',
      pathname: 'properties',
      icon: AllPropertyIcon,
    },
    {
      Name: 'My Portfolio',
      pathname: 'myProperties',
      icon: MyPropertyIcon,
    },
    {
      Name: 'My Wallet',
      pathname: 'wallet',
      icon: MyWalletIcon,
    },
    {
      Name: 'Settings',
      pathname: 'setting',
      icon: SettingsIcon,
    },
  ];
  const size = useRef(18);

  const sizeSettings = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 390) {
      size.current = 12;

    }
  };
  sizeSettings();
  return (
    <div className={'flex flex-col gap-y-8  py-3 border-r border-custom-lemongreen h-screen'}>
        <Link to='/'>
            <Logo className='w-full lg:w-full md:w-full h-10'/>
        </Link>
        <div>
            <ul className='flex flex-col p-3 pl-4 xl:pl-8 gap-2.5 md:gap-10 justify-start md:justify-start'>
                {
                    Dashboard.map((dashboard, index) => {
                      return (
                            <Link to={`/app/${dashboard.pathname}`} key={index}
                                  className={`flex gap-1 text-xs xl:text-sm items-center font-semibold cursor-pointer ${pageName === dashboard.pathname ? 'bg-custom-midnightgreen rounded-full p-2 xl:px-4 px-2 text-white' : 'text-custom'}`}>
                                <dashboard.icon size={size.current}
                                                color={pageName === dashboard.pathname ? '#ffffff' : '#6D7080'}/>
                                <span
                                    className={`${pageName === dashboard.pathname ? 'text-white font-semibold' : ''}`}>{dashboard.Name}</span>
                            </Link>
                      );
                    })
                }
            </ul>

        </div>
        <div onClick={() => dispatch(handleLogoutRequest())} className={'flex mt-auto pb-20 gap-1 text-xs md:text-sm items-center font-semibold cursor-pointer p-3 pl-8 text-red-500'}>
            <Logout />
            <p>Logout</p>
        </div>
    </div>
  );
}

export default Sidebar;
