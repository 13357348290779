import { getNextOfKin, postNextOfKin, updateNextOfKin } from '../services/userService';
import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';
import { NextOfKin } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';

export const useNextOfKin = () => {
  const [nextOfKinData, setNextOfKinData] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const postNextOfKinData = async (payload: NextOfKin) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await postNextOfKin(payload);
      // console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setNextOfKinData(userResponse);
        setLoading(false);
        showToast('Success', response.data.message, 'success');
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getNextOfKinData = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    try {
      const response = await getNextOfKin(id);
      // console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data[0];
      if (userResponse) {
        setNextOfKinData(userResponse);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e.response.data.message);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const updateNextOfKinData = async (payload: NextOfKin) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await updateNextOfKin(payload);
      // console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data?.property;
      if (userResponse) {
        setNextOfKinData(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true };
      }
    } catch (e: any) {
      // console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };

  return { getNextOfKinData, postNextOfKinData,  loading, updateNextOfKinData, nextOfKinData };
};
