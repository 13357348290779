import React from 'react';
import Navbar from './Navbar';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../Pages/Dashboard/Dashboard';
import Footer from './Footer';
import AllProperties from '../../Pages/Dashboard/AllProperties';
import Viewproperty from '../../Pages/Dashboard/Viewproperty';
import Listing from '../../Pages/Dashboard/Listing';
import Wallet from '../../Pages/Dashboard/Wallet';
import Setting from '../../Pages/Dashboard/Setting';
import ListerDashboard from '../../Pages/Dashboard/Lister/Dashboard';
import ListerPortfolio from '../../Pages/Dashboard/Lister/Portfolio';
import AddProperty from '../../Pages/Dashboard/AddProperty';
import MyProperties from '../../Pages/Dashboard/MyProperties';
import Sidebar from './SideBar';

function Layout() {
  const state = {
    userType: 'liste',
  };
  return (
    <div className='relative h-screen flex'>
        <div className={'lg:w-[15%] lg:block hidden'}>
            <Sidebar />
        </div>
        <div className={'lg:w-[85%] w-full overflow-y-auto flex flex-col'}>
            <Navbar />
            <div className='bg-custom-white overflow-y-auto flex-1'>
                <Routes>
                    <Route path='/dashboard' element={state.userType === 'lister' ? <ListerDashboard/> : <Dashboard />} />
                    <Route path='/properties' element={state.userType === 'lister' ? <ListerPortfolio/> : <AllProperties />} />
                    <Route path='/myProperties' element={state.userType === 'lister' ? <ListerPortfolio/> : <MyProperties />} />
                    <Route path='/properties/:id' element={<Viewproperty />} />
                    <Route path='/listing' element={<Listing />} />
                    <Route path='/wallet' element={<Wallet />} />
                    <Route path='/setting' element={<Setting />} />
                    <Route path='/addproperty' element={<AddProperty />} />
                </Routes>
            </div>
            <Footer />
        </div>
    </div>
  );
}

export default Layout;
