import '../../Stylesheets/walletLoader.css';
export const WalletLoader = () => {
  return (
            <>
                <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    {/*<div className="dot"></div>*/}
                </section>

            </>
  );
}
;
