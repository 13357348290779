import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Components/Layout/Footer';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { useLandingPage } from '../../Services/hooks/landingpage';
import { formatCurrency } from '../../Utils/currencyFormat';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { User } from '../../Storeredux/constants/actionTypes';
import { shortenSentence } from '../../Utils/shorttenText';
import { AllPropertyPagination } from '../../Components/Pagination/AllPropertiesPagination';
import PropertyTabs from '../../Components/Tab/propertyTab';


function  Property() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [type, setType] = useState<string>('');

  const { GetHomeProperty, property, loading: pageLoading, paginate } = useLandingPage();
  const tabs = [
    { id: 'newListing', label: 'New Listing' },
    { id: 'funded', label: 'Funded' },
    { id: 'sold', label: 'Sold' },

  ];
  const history = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tab = params.get('tab');
  console.log(tab);

  const handleTabChange = (index: number) => {
    console.log(index);
    setActiveTabIndex(index);
    console.log(`Selected Tab: ${tabs[index].id}`);
    const tabLabel = tabs[index].id;
    setType(tabLabel);
    history(`/property?tab=${encodeURIComponent(tabLabel)}`);
    const updatedData = {
      page: 1,
      type: tabLabel,
    };
    GetHomeProperty(updatedData);
    console.log(`Selected Tab: ${tabLabel}`);
  };

  // const handleSearch = async (params: any) => {
  //   await getFilteredHomeProperty(params);
  // };
  useEffect(() => {
    const data = {
      page: 1,
      type: 'newListing',
    };
    GetHomeProperty(data);
  }, []);
  return (
      <div className={'py-10'}>
        <div className='text-center '>
          <div className={'lg:w-10/12 mx-auto bg-[#D9FCD5] rounded-xl py-7 flex flex-col gap-3.5'}>
            <div className={'flex justify-center py-2'}>
              <PropertyTabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange}/>
            </div>
          </div>
        </div>
        {pageLoading ?
            <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
              <PageLoader/>
            </div> :
          property.length === 0 ? <div className={'h-screen flex flex-col items-center pt-28'}>No property available</div> :
            <>
              <div className='featuredProperty lg:w-10/12 mx-auto !pb-20'>
                {property?.map((investment: User, index) => {
                  return (
                      <Link to={`/app/properties/${investment?.property?.id}`} key={index}
                            className='rounded-3xl overflow-hidden w-[285px] sm:w-[200px] md:w-[285px] bg-white border border-custom-grey'>
                        <div className='relative'>
                          <img src={investment?.property.thumbnail} alt='investment'
                               className='w-full h-44 bg-green-200'/>
                          <span
                              className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-3 ${investment?.property?.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                          {investment?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                        </span>
                        </div>
                        <div className='p-3 pb-4 flex flex-col gap-y-3'>
                          <div className={'flex gap-2 '}>
                          <span
                              className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Bed/>
                            </span>
                            <span>{investment?.property?.details?.rooms_no}</span>
                          </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Shower/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Toilet/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                          </div>
                          <h1 className=''>{shortenSentence(investment?.property?.details?.property_title)}</h1>
                          <div>
                            <div
                                className={`w-full  rounded-full h-2 ${investment?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC26733]'}`}
                                style={{ mixBlendMode: 'multiply' }}>
                              <div
                                  className={` h-2 rounded-full ${investment?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC267]'}`}
                                  style={{ width: `${investment?.percent_funded}%` }}></div>
                            </div>
                            <div className={'flex justify-between w-full text-xxs text-custom-darkgreen pt-0.5'}>
                              <p>
                                {formatCurrency(investment?.amount_invested?.totalInvestedAmount)} Invested
                              </p>
                              <p>
                                {`${investment?.property?.available_slots} ${investment?.property?.available_slots <= 1 ? 'slot' : 'slots'}`} remaining
                              </p>
                            </div>
                          </div>
                          <div className={'bg-[#F9F9F9] p-3 rounded-2xl flex flex-col gap-y-2 w-full'}>
                            <div className='flex justify-between text-xs'>
                              <span>Price Per Slot:</span>
                              <span
                                  className={'font-semibold'}>{formatCurrency(investment?.price_per_slot)}</span>
                            </div>
                            <div className='flex justify-between text-xs'>
                              <span>Yearly Investment Return:</span>
                              <span
                                  className={'font-semibold'}>{investment?.yearly_investment_return || 0}%</span>
                            </div>
                            <div className='flex justify-between text-xs'>
                              <span>Earnings On Slot:</span>
                              <span
                                  className={'font-semibold'}>{formatCurrency(investment?.earning_on_slot || 0)}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                  );
                })}
              </div>
            </>
        }
        <div className={'pb-12 flex justify-end lg:w-10/12 mx-auto'}>
          <AllPropertyPagination paginate={paginate} getPropertyCoOwnData={GetHomeProperty} type={type}/>
        </div>
        <Footer/>
      </div>

  )
  ;
}

export default Property;
