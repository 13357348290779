import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import { useUserData } from '../../Services/hooks/User';
import { UserData } from '../../Services/constant/serviceType';
// import { ReactComponent as Support } from '../../Assets/Icons/supportUser.svg';

function PersonalInformation() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { getUserInfo } = useUserData();
  // console.log(user);
  const [userStateData, setUserStateData] = useState<UserData>({
    first_name: '',
    last_name: '',
    maiden_name: '',
    email: '',
    gender: '',
    in_diaspora: user.in_diaspora,
    dob: user.dob,
    country_code: user.country_code,
    phone: '',
  });
  console.log('userStateData', userStateData);
  // const [readOnly, setReadOnly] = useState(true);
  // const gender = [
  //   {
  //     value: 'male',
  //     label: 'Male',
  //   },
  //   {
  //     value: 'female',
  //     label: 'Female',
  //   },
  // ];
  // const isFormComplete = areAllKeysFilled(userStateData);
  // const onChange = (e: any, fieldKey: string) => {
  //   console.log('next', e.target.value, fieldKey);
  //   setUserStateData((prevData) => ({
  //     ...prevData,
  //     [fieldKey]: e.target.value,
  //   }));
  // };
  // const onSubmit = async (e: { preventDefault: () => void; }) => {
  //   e.preventDefault();
  //   console.log(user);
  //   let info = await updateUserData(userStateData, user.id);
  //   console.log('iii', info);
  //   // let info = await getUserInfo(user.id);
  //   info =  info.response;
  //   console.log('jjj', info);
  //   setUserStateData({
  //     first_name: info.firstname  || '',
  //     last_name: info.lastname || '',
  //     maiden_name: info.maiden_name || '',
  //     email: info.email || '',
  //     gender: info.gender || '',
  //     in_diaspora: info.in_diaspora || false,
  //     dob: info.dob || false,
  //     country_code: info.country_code || '',
  //     phone: info.phone || false,
  //   });
  //   setReadOnly(true);
  // };

  useEffect(() => {
    getUserInfo(user.id);
    if (user) {
      setUserStateData({
        first_name: user.firstname  || '',
        last_name: user.lastname || '',
        maiden_name: user.maiden_name || '',
        email: user.email || '',
        gender: user.gender || '',
        in_diaspora: user.in_diaspora || false,
        dob: user.dob || false,
        country_code: user.country_code || '',
        phone: user.phone || false,
      });
    }
  }, []);
  return (
      <div className='p-4 md:p-10 flex flex-col gap-10'>
          <div className={'bg-white rounded-xl px-6 py-5 flex flex-col gap-y-6'} style={{ boxShadow: '0px 4px 20px 0px #5E84C20F' }}>
              <div className={'flex justify-between'}>
                  <p>Name</p>
                  <p className={'text-custom-darkgreen font-semibold'}>{user.lastname + ' ' + user.firstname}</p>
              </div>
              <div className={'flex justify-between'}>
                  <p>Email</p>
                  <p className={'text-custom-darkgreen font-semibold'}>{user.email}</p>
              </div>
              <div className={'flex justify-between'}>
                  <p>Phone Number</p>
                  <p className={'text-custom-darkgreen font-semibold'}>{user.phone}</p>
              </div>
              <div className={'flex justify-between'}>
                  <p>Date of birth</p>
                  <p className={'text-custom-darkgreen font-semibold'}>{user.dob}</p>
              </div>
              {/*<hr className={'border-dashed bg-black'}/>*/}
              {/*<div className={'flex justify-between items-center'}>*/}
              {/*<div className={'flex justify-between items-center'}>*/}
              {/*    <p className={'font-semibold text-sm'}>Need help updating information?</p>*/}
              {/*    <div className={'border border-custom-grey rounded-xl text-custom py-2 px-4 flex items-center justify-center gap-1.5 text-sm'}>*/}
              {/*        <Support />*/}
              {/*        <p>Support</p>*/}
              {/*    </div>*/}
              {/*</div>*/}
          </div>
          {/*<button className={'border border-custom-grey rounded-xl text-custom py-2 hover:bg-red-600 hover:text-white px-4 flex items-center justify-center gap-1.5 text-sm'}>*/}
          {/*    Delete*/}
          {/*</button>*/}
      </div>
  );
}

export default PersonalInformation;
