import React, { useEffect, useState } from 'react';
// import SelectField from '../../Form/SelectField';
import InputField from '../../Form/InputField';
import { ArrowCircleLeft2 } from 'iconsax-react';
import { useAccount } from '../../../Services/hooks/account';
import SearchSelectField from '../../Form/SearchSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Storeredux/reducers/rootReducer';
import { userData } from '../../../Storeredux/constants/actionTypes';
import CustomButton from '../../Form/CustomButton';
import { areAllKeysFilled } from '../../../Utils/formValidator';

function AddBank(props: any) {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { wallet } = UserState;
  const [data, setData] = useState({
    account_number: '',
    bank_code : '',
    account_name : '',
    wallet_id : wallet?.id,
  });
  const [errors, setError] = useState(null);
  const { getBanks, bank: bankData, storeAccountInfo, validateAccountInfo, validateLoading, loading } = useAccount();
  const isFormComplete = areAllKeysFilled(data);
  const [errorState, setErrorState] = useState('');
  const validateAccountNumber = (phoneNumber: string) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const expectedLength = 10;
    if (digitsOnly.length === expectedLength) {
      setErrorState('' );
    } else {
      setErrorState('Invalid Account Number' );
    }
  };
  const onChange = (e: any, fieldKey: string) => {
    console.log('next', e.target.value, fieldKey);
    if (fieldKey === 'account_number') {
      validateAccountNumber(e.target.value);
    }
    setData({ ...data, [fieldKey]: e.target.value });
  };

  const selectOnChange = async (e: any, fieldKey: string) => {
    console.log('next', e, fieldKey);
    setError(null);
    // setData({ ...data, });
    const validateData = {
      'account_no': data.account_number,
      'bank_code': e,
    };
    if (e) {
      const { success, results, error } = await validateAccountInfo(validateData);
      console.log(results);
      if (success) {
        setData({ ...data, account_name: results.account_name, [fieldKey]: e  });
      } else {
        setError(error);
      }
    }
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    storeAccountInfo(data);
  };

  useEffect(() => {
    getBanks();
  }, []);
  return (
    <div className='flex flex-col gap-3.5'>
      <ArrowCircleLeft2 size="32" color="#20996B" className='cursor-pointer' variant="Bold" onClick={() => props.close()}/>
      <h1 className='text-3xl font-bold pt-2.5 pb-5'>Banks</h1>
      <form onSubmit={handleSubmit} className='flex flex-col gap-3.5'>
        <div className='flex w-full gap-5 py-1.5'>
          <div className=' w-1/2 '>
            <InputField name='Account Number' value={data.account_number} fieldKey={'account_number'} onChange={onChange}
                        readOnly={false} type='number' className='w-full'/>
            {errorState && (
                <p className={'text-xs text-red-600 italic'}>{errorState}</p>
            )}
          </div>
          <SearchSelectField name='Select Bank' className='w-1/2' onChange={selectOnChange} fieldKey={'bank_code'}
                             options={bankData} selectedValue={'select bank'}/>
        </div>
        <div className=' w-full gap-5 py-1.5'>
          <InputField name='Account Name' type='text' className='w-full'
                      value={validateLoading ? 'loading...' : data.account_name} readOnly={true}/>
          {errors && (<p className={'text-xs text-red-600 italic'}>{errors}</p>)}
        </div>
        <div className='flex w-full gap-5 py-1.5'>
          {/*<button className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>*/}
          {/*            Save Changes*/}
          {/*</button>*/}
          <CustomButton
              isLoading={loading}
              disabled={!isFormComplete}
              children={'Save'}
              className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'
          />
        </div>
      </form>

    </div>
  );
}

export default AddBank;
