import React from 'react';
import ForgotPasswordForm from '../../Components/SignUp/ForgotPasswordForm';


function ForgotPassword() {
  return (
    <div>
      <ForgotPasswordForm />
    </div>
  );
}

export default ForgotPassword;
