import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';
import { getInvestmentDetails, payWithWallet, postPaymentRequest, verifyPaymentData } from '../services/paymentService';
import { PostPayment } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';

export const usePayment = () => {
  const [investmentDetails, setInvestmentDetails] = useState<User>();
  const [initiateDetails, setInitiateDetails] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const getInvestmentInfo = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    // setLoading(true);
    try {
      const response = await getInvestmentDetails(id);
      console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        // setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      // setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };

  const postInitiatePayment = async (payload: PostPayment) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await postPaymentRequest(payload);
      console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInitiateDetails(userResponse);
        setLoading(false);
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e?.response?.data?.message };
    }

    return userResponse;
  };

  const verifyPayment = async (reference: string) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await verifyPaymentData(reference);
      console.log('SINGLE PROPERTY', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setLoading(false);
        showToast('Success', response.data.message, 'success');
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };

  const postPayWithWallet = async (payload: PostPayment) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await payWithWallet(payload);
      console.log('SINGLE PROPERTY', response);
      userResponse = response;
      if (userResponse) {
        setInitiateDetails(userResponse);
        setLoading(false);
        showToast('Success', response.data.message, 'success');

        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');

      return { success: false, error: e?.response?.data?.message };
    }

    return userResponse;
  };

  return { getInvestmentInfo, loading, postPayWithWallet, investmentDetails, postInitiatePayment, initiateDetails, verifyPayment };
};
