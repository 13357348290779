import { getDashboard } from '../services/userService';

export const getDashboardData = async (id: string | number) => {
  let userResponse = null;
  try {
    const response = await getDashboard(id);
    // console.log('dashboard', response);
    userResponse = response;
    if (userResponse) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return { success: true, result: response.data.data };
    }
  } catch (e: any) {
    // console.log(e.response);
    return { success: false, error: e.response?.data?.message };
  }

  return userResponse;
};
