import { postForgottenPassword } from '../services/authservices';
import { ForgotPassword } from '../constant/serviceType';

export const forgotPasswordMethod = async (payload: ForgotPassword) => {
  let userResponse = null;
  try {
    const response = await postForgottenPassword(payload);
    // console.log('registerUser', response);
    userResponse = response?.data?.data;
    if (userResponse) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // const { access_token } = userResponse;
      return { success: true, data: userResponse };
    }
  } catch (e: any) {
    // console.log(e.response.data.message);
    return { success: false, error: e.response?.data?.message };
  }

  return userResponse;
};
