import React from 'react';
// import Navbar from "../Components/Layout/Navbar";
import LoginForm from '../../Components/Login/LoginForm';


function Login() {
  return (
    <div>
      {/*<Navbar />*/}
      <LoginForm />
    </div>
  );
}

export default Login;
