import React, { useState, useRef, useEffect } from 'react';

interface Option {
  code: string;
  name: string;
}

interface SelectFieldProps {
  name: string;
  options: Option[];
  selectedValue: string;
  readOnly?: boolean;
  fieldKey: string;
  onChange: (selectedOption: string, fieldKey: string) => void;
  className?: string;
}

const SearchSelectField: React.FC<SelectFieldProps> = (props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(props.options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFilteredOptions(
      props.options.filter(option =>
        option.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }, [searchValue, props.options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option: Option) => {
    setSearchValue(option.name);
    props.onChange(option.code, props.fieldKey);
    setShowOptions(false);
  };

  return (
        <div className={`input-container ${props.className} ${props.readOnly ? 'readOnlyStyle' : ''}`} ref={dropdownRef}>
            <label className="input-custom-label input-focus" data-shrink="true">
                {props.name}
            </label>

            <div className={'input-custom-input input-custom-input-focus'}>
                <input
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onClick={() => !props.readOnly && setShowOptions(!showOptions)}
                    placeholder="Select..."
                    disabled={props.readOnly}
                    className={`custom-input focus-visible:outline-0 ${props.readOnly ? 'readOnlyStyle' : ''}`}
                />
                <fieldset aria-hidden="true" className="MuiOutlinedInput-notchedOutline css-nqlg3w">
                    <legend className="css-14lo706">
                        <span>{props.name}</span>
                    </legend>
                </fieldset>

                {showOptions && (
                    <div className="dropdown-options">
                        {filteredOptions.length > 0 ? (
                          filteredOptions.map((option, index) => (
                                <div
                                    key={index}
                                    className="dropdown-option"
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option.name}
                                </div>
                          ))
                        ) : (
                            <div className="dropdown-option">No options found</div>
                        )}
                    </div>
                )}
            </div>
        </div>
  );
};

export default SearchSelectField;
