import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import PinInput from '../Form/InputPin';

function OtpVerification({ next }: any) {
  const [error] = useState('');
  const navigation = useNavigate();
  const handleComplete = (pin: any) => {
    console.log('PIN entered:', pin);
  };

  return (
    <>
      <div
        className={`${next === 'otp' ? '' : 'hidden'} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
        style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>

        <form autoComplete="off" className='p-7 bg-white' >
          <div className='flex gap-1.5 items-center'>
            <h3 className='font-semibold text-2xl'>
                            OTP Verification
            </h3>
          </div>
          <div className='py-5'>
            <p className={'text-sm text-green-500'}>
                            Please enter the one-time verification code we've sent to your registered email .This code is essential for authentication and ensuring the security of your account. Thank you for using our services
            </p>
          </div>
          <div className='pb-5'>
            <PinInput length={6} onComplete={handleComplete} />
            {error && <p className="text-red-600 text-xs pt-1.5">{error}</p>}
          </div>
          <div className='flex justify-center'>
            <button onClick={() => navigation('/login')}
              className='w-6/12 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'>
                            Proceed
            </button>
          </div>
          <div className='text-center py-3 text-xs'>
            <p>Already have an account? <Link to='/login'
              className='font-semibold text-custom-green'>Login</Link></p>
          </div>
        </form>
      </div>
    </>
  );
}
export default OtpVerification;
