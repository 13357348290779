import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CancelIcon } from '../../Assets/Icons/cancel.svg';
import { useLandingPage } from '../../Services/hooks/landingpage';
import { ReactComponent as NameIcon } from '../../Assets/Icons/nameIcon.svg';
import { ReactComponent as EmailIcon } from '../../Assets/Icons/emailicon.svg';
import { ReactComponent as PhoneInputIcon } from '../../Assets/Icons/phoneinputicon.svg';
import { ReactComponent as Telegram } from '../../Assets/Icons/telegramicon.svg';

interface Props {
  isOpen: boolean;
  onHide: () => void;
}

function ContactUs({ isOpen, onHide }: Props) {
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    property_type: 'home',
    message: '',
  });
  const { loading, ContactUsRequest } = useLandingPage();
  const [errorState, setErrorState] = useState({
    phone: '',
    bvn: '',
  });
  const validatePhoneNumber = (phoneNumber: string) => {
    console.log('validate', phoneNumber);
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const expectedLength = 11;
    if (digitsOnly.length === expectedLength) {
      setData({ ...data, phone: phoneNumber });
      setErrorState({ ...errorState, phone: '' });
    } else {
      // setData({ ...data, phone: '' });
      setErrorState({ ...errorState, phone: 'Invalid Phone Number' });
    }
  };
  const onChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === 'phone') {
      console.log(e.target.value);
      validatePhoneNumber(e.target.value);
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const areAllKeysFilled = (obj: any) => {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      const value = obj.hasOwnProperty(key);
      if (value) {
        if (!obj[key]) {
          return false;
        }
      }
    }
    return true;
  };
  const isFormComplete = areAllKeysFilled(data);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (errorState.phone) {
      setErrorState({ ...errorState, bvn: 'Please enter a valid phone number' });
    } else if (isFormComplete) {
      await ContactUsRequest(data);
      setData({ ...data, name: '',
        email: '',
        phone: '',
        property_type: 'home',
        message: '' },
      );
      onHide();
    } else {
      setErrorState({ ...errorState, bvn: 'Please complete the form information' });
    }

  };
  return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onHide}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full transform overflow-hidden lg:max-w-[55%] xl:max-w-[40%] rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <div className='flex justify-end px-4 py-4'>
                                    <CancelIcon onClick={onHide} className='cursor-pointer'/>
                                </div>
                                <div className='justify-center p-14 pt-7'>
                                    <h1 className={'font-bold text-2xl pb-3'}>Contact Us</h1>
                                    <form className={'w-full'} onSubmit={onSubmit}>
                                        <div className='py-3'>
                                            <label className='font-semibold'>
                                                Your Name
                                            </label>
                                            <div className="relative">
                                                <NameIcon
                                                    className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                                                <input name={'name'} value={data.name} onChange={onChange}
                                                       className="focus-visible:outline-0 w-full p-4 pl-10 border border-input-color rounded-custom"
                                                       type="text" placeholder="Enter your name"/>
                                            </div>
                                        </div>
                                        <div className='py-3 flex gap-2 md:gap-3 xl:gap-8 lg:gap-8 '>
                                            <div className={'w-full'}>
                                                <label className='font-semibold'>
                                                    Email
                                                </label>
                                                <div className="relative">
                                                    <EmailIcon
                                                        className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                                                    <input name={'email'} value={data.email} onChange={onChange}
                                                           className="focus-visible:outline-0 w-full p-4 pl-10 border border-input-color rounded-custom"
                                                           type="email" placeholder="Enter your email"/>
                                                </div>
                                            </div>
                                            <div className={'w-full'}>
                                                <label className='font-semibold'>
                                                    Phone Number
                                                </label>
                                                <div className="relative">
                                                    <PhoneInputIcon
                                                        className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                                                    <input name={'phone'} value={data.phone} onChange={onChange}
                                                           className="focus-visible:outline-0 w-full p-4 pl-10 border border-input-color rounded-custom"
                                                           type="number" placeholder="Enter your Phone No"/>
                                                </div>
                                                {errorState.phone && (
                                                    <div className="text-red-500 text-xs mt-1">
                                                        {errorState.phone}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/*<div className='py-3'>*/}
                                        {/*    <label className='font-semibold'>*/}
                                        {/*        Property Type*/}
                                        {/*    </label>*/}
                                        {/*    <div className="relative">*/}
                                        {/*        <PropertyType className="absolute w-6 h-6 text-gray-500 left-2 top-1/2 transform -translate-y-1/2" />*/}
                                        {/*        <select className="focus-visible:outline-0 w-full p-4 pl-10 border border-input-color rounded-custom" >*/}
                                        {/*            <option>Select Property Type</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className='py-3'>
                                            <label className='font-semibold'>
                                                Message
                                            </label>
                                            <div className="relative">
                                                <textarea name={'message'} value={data.message} onChange={onChange}
                                                          rows={4}
                                                          className="focus-visible:outline-0 w-full p-4 pl-10 border border-input-color rounded-custom"/>
                                            </div>
                                        </div>
                                        {errorState.bvn && (
                                            <div className="text-red-500 text-xs my-1">
                                                {errorState.bvn}
                                            </div>
                                        )}
                                        <button
                                            className={`${isFormComplete ? 'bg-green-600' : 'bg-green-600'} flex justify-between self-center items-center h-fit rounded-custom border-0 text-white py-3.5 px-5`}>
                                            <div className='flex justify-center px-1.5'>
                                                <Telegram className=''/>
                                            </div>
                                            {loading ? <div className="lds-spinner">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> : <div
                                                className={'border-l border-gray-400 font-bold px-3 flex flex-row'}>Send
                                                Request</div>
                                            }
                                            {/*<div className='border-l border-gray-400 font-bold px-3 flex flex-row'>Send Request</div>*/}
                                        </button>
                                    </form>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
  );
}

export default ContactUs;
