import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import signupCorporate from '../../Assets/Images/signupCorporate.png';
import signupListner from '../../Assets/Images/signupLister.png';
import { ReactComponent as CancelIcon } from '../../Assets/Icons/cancel.svg';

function ListnerModal(props: any) {

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onHide}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full transform overflow-hidden lg:max-w-[75%] xl:max-w-[50%] rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className='flex justify-end px-4 py-2'>
                  <CancelIcon onClick={props.onHide} className='cursor-pointer'/>
                </div>
                <div className='lg:flex lg:gap-6 justify-center p-14'>
                  <Link className='block register-card p-4 md:mb-10 mb-10 lg:mb-0 w-full hover:bg-custom-midnightgreen hover:text-white'
                    to='personal'>
                    <div>
                      <img src={signupListner} alt='Sign Up Player'
                        className='mx-auto lg:max-w-[35%] '/>
                    </div>
                    <h1 className='text-xl font-semibold py-5 text-center'>
                                            Personal
                    </h1>
                    {/*<p className='text-sm font-semibold text-start'>*/}
                    {/*    Join our talent pool of over 10,000 students*/}
                    {/*    learning and growing their skills, with over*/}
                    {/*    5,000 tutors ready to guide you.*/}
                    {/*</p>*/}
                  </Link>
                  <Link to='cooperate' className='block register-card p-4 md:mb-10 mb-10 lg:mb-0 w-full hover:bg-custom-midnightgreen hover:text-white overflow-hidden'>
                    <div>
                      <img src={signupCorporate} alt='Sign Up Player'
                        className='w-full lg:max-w-max md:max-w-[40%] md:mx-auto'/>
                    </div>
                    <h1 className='text-xl text-center font-semibold py-5'>
                                            Cooperate
                    </h1>
                    {/*<p className='text-sm font-semibold text-start'>*/}
                    {/*    Join our talent pool of over 10,000 students*/}
                    {/*    learning and growing their skills, with over*/}
                    {/*    5,000 tutors ready to guide you.*/}
                    {/*</p>*/}
                  </Link>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ListnerModal;
