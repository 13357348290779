import React, { useEffect, useRef } from 'react';
// import Navbar from "../Components/Layout/Navbar";
import Partners from '../../Components/Homepage/Partners';
// import PricingPlan from '../../Components/Homepage/PricingPlan';
import Benefit from '../../Components/Homepage/Benefit';
import Contact from '../../Components/Homepage/Contact';
import Advert from '../../Components/Homepage/Advert';
import Footer from '../../Components/Layout/Footer';
import FeaturedProject from '../../Components/Homepage/FeaturedProject';
import Homepage from '../../Components/Homepage/Homepage';
// import Steps from '../../Components/Homepage/Steps';
import Customer from '../../Components/Homepage/Customer';
import { useLocation } from 'react-router-dom';

const LandingPage = (() => {
  const targetDivRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.scrollTo && location.state.scrollTo === 'targetDiv' && targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);
  return (
    <div>
      {/*<Navbar  />*/}
      <Homepage />
      <Partners />
      {/*<Steps />*/}
      {/*<PricingPlan />*/}
      <FeaturedProject />
      <Benefit />
      <Contact ref={targetDivRef}  />
      <Customer />
      <Advert />
      <Footer />
    </div>
  );
});
export default LandingPage;
