import { authorized } from '../axios';
import { CHECK_PAYMENT, GET_FEES, GET_PAYMENT_HISTORY, GET_WALLET } from '../service-routes';
import { GetFee } from '../constant/serviceType';

export const getWallet = (id: string | number) => {
  return authorized.get(`${GET_WALLET}/${id}`);
};

export const getPaymentHistoryList = (id: string | number) => {
  return authorized.get(`${GET_PAYMENT_HISTORY}/${id}`);
};

export const checkPayment = (id: string | number, date: string) => {
  return authorized.get(`${CHECK_PAYMENT}/${date}/${id}`);
};

export const getFee = (payload: GetFee) => {
  return authorized.post(GET_FEES, payload);
};

