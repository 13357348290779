import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Bank } from 'iconsax-react';
import { ReactComponent as NoAccount } from '../../Assets/Icons/noaccount.svg';
import { Link } from 'react-router-dom';
import { useAccount } from '../../Services/hooks/account';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import InputField from '../Form/InputField';
import { formatCurrency2 } from '../../Utils/currencyFormat';
import { ObjectType } from '../../Services/constant/serviceType';
import CustomButton from '../Form/CustomButton';

function WithdrawalModal(props :any) {
  const [cont, setContinue] = useState(false);
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { wallet } = UserState;
  const { getBeneficiariesInfo, beneficiaryData, bank, getBanks, WithdrawFunds, validateLoading } = useAccount();
  const [isAccountValid, setIsAccountValid] = useState(false);
  const [amount, setAmount] = useState('');
  const getBankNameByCode = (code: any) => {
    const bankName: any = bank.find((item: any) => item.code === code);
    return bankName ? bankName.name : 'Bank not found';
  };
  const [active, setActive] = useState<number | null>(null);
  const handleAmoutSelect = (value: any) => {
    setAmount(value);
  };
  const [activeDetails, setActiveDetails] = useState<ObjectType>({});
  const handlePostWithdraw = async (e: any) => {
    e.preventDefault();
    const data = {
      'account_number': activeDetails.account_number,
      'bank_code': activeDetails.bank_code,
      'amount': amount.toString(),
      'narration': 'withdraw kekere',
      'wallet_id': wallet?.id,
    };
    await WithdrawFunds(data);
    setContinue(false);
    setIsAccountValid(false);
    setActive(null);
    props.onHide();
  };
  useEffect(() => {
    getBeneficiariesInfo(wallet?.id);
    getBanks();
  }, []);
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onHide}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25"/>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden max-w-[35%] rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div>
                  <div className="flex items-center justify-between px-8 py-5 border border-custom border-b-2" style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>
                    <h1 className='font-semibold text-xl'>Withdraw</h1>
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="text-gray-400 focus:outline-none focus:text-gray-600"
                        onClick={props.onHide}
                      >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className='py-7 pb-10 w-10/12 mx-auto '>
                    <div className={`flex flex-col gap-y-3.5 ${cont ? 'hidden' : ''}`}>
                      <div className="bg-custom-light-green rounded-custom flex items-center gap-x-3.5 p-4">
                        <Bank size="32" color="#20996B" variant="Bold"/>
                        <p className='text-sm'>To Bank Account</p>
                      </div>
                      <button onClick={() => setContinue(true)}
                        className='btn bg-custom-midnightgreen rounded-custom border-0 text-sm font-bold text-white py-3'>
                                                Continue
                      </button>
                    </div>
                    {beneficiaryData.length > 0 ?
                        <div className={`flex flex-col gap-y-3.5 ${cont && !isAccountValid ? '' : 'hidden'}`}>
                          <p>Select Bank Account</p>
                          {
                            beneficiaryData.map((data: any, index) => (
                                <div key={index + 1} onClick={() => {
                                  setActive(index);
                                  setActiveDetails(data);
                                }}
                                     className={`${active === index ? 'border-2 border-custom-darkgreen' : ''} bg-custom-light-green rounded-custom flex items-center gap-x-3.5 p-4`}>
                                  <Bank size="32" color="#20996B" variant="Bold"/>
                                  <div className={'flex flex-col gap-y-1'}>
                                    <p className='text-sm'>{getBankNameByCode(data.bank_code)}</p>
                                    <p className='text-sm'>{data.account_number}</p>
                                    <p className='text-sm'>{data.account_name}</p>
                                  </div>
                                </div>
                            ))
                          }
                          <button onClick={() => setIsAccountValid(true)}
                                  className='btn bg-custom-midnightgreen rounded-custom border-0 text-sm font-bold text-white py-3'>
                            Continue
                          </button>
                        </div> :
                        <div className={`flex flex-col justify-center items-center gap-y-3.5 ${cont && !isAccountValid ? '' : 'hidden'}`}>
                          <NoAccount/>
                          <p className='text-sm'>No beneficiary added</p>
                          <Link to="/app/setting?tab=bankaccount"
                                className='text-center w-full btn bg-custom-midnightgreen rounded-custom border-0 text-sm font-bold text-white py-3'>
                            Add Account
                          </Link>
                        </div>
                    }
                    <form onSubmit={handlePostWithdraw} className={`flex flex-col gap-y-2 ${cont && isAccountValid ? '' : 'hidden'}`}>
                      <p>
                        Enter Amount
                      </p>
                      <div className='py-3'>
                        <InputField name='Amount' readOnly={false} type='text' className='w-full' onChange={(e: any) => setAmount(e.target.value.replace(/[₦,]/g, ''))}
                                    value={formatCurrency2(amount)}/>
                      </div>
                      <div>
                        <p>
                          Suggestions
                        </p>
                        <div className='flex gap-3 my-3 flex-wrap'>
                          <button type='button' className='rounded-full py-2 px-6 text-xs'
                                  onClick={() => handleAmoutSelect(50000)}
                                  style={{ backgroundColor: '#F9F9F9' }}>
                            ₦50,000.00
                          </button>
                          <button type='button' className='rounded-full py-2 px-6 text-xs'
                                  onClick={() => handleAmoutSelect(150000)}
                                  style={{ backgroundColor: '#F9F9F9' }}>
                            ₦150,000.00
                          </button>
                          <button type='button' className='rounded-full py-2 px-6 text-xs'
                                  onClick={() => handleAmoutSelect(400000)}
                                  style={{ backgroundColor: '#F9F9F9' }}>
                            ₦400,000.00
                          </button>
                          <button type='button' className='rounded-full py-2 px-6 text-xs'
                                  onClick={() => handleAmoutSelect(800000)}
                                  style={{ backgroundColor: '#F9F9F9' }}>
                            ₦800,000.00
                          </button>
                          <button type='button' className='rounded-full py-2 px-6 text-xs'
                                  onClick={() => handleAmoutSelect(1000000)}
                                  style={{ backgroundColor: '#F9F9F9' }}>
                            ₦1,000,000.00
                          </button>
                        </div>
                      </div>
                      <div>
                        <CustomButton
                            isLoading={validateLoading}
                            disabled={!amount}
                            children={'Continue'}
                            className={'w-full btn bg-custom-midnightgreen rounded-custom border-0 text-sm font-bold text-white py-3'}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default WithdrawalModal;
