import { Icon } from '../../Services/constant/serviceType';

export const MyPropertyIcon = ({ size, color }: Icon) => {
  return (
      <>
          <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_5268_1552)">
                  <path
                      d="M18.0007 10.723C17.8805 10.9607 17.7841 11.2147 17.6357 11.4335C16.717 12.7907 15.7859 14.14 14.8606 15.4927C14.1985 16.4608 13.278 16.9424 12.1106 16.9441C9.56877 16.9481 7.02696 16.9455 4.48559 16.9455C4.42836 16.9455 4.37069 16.9455 4.30202 16.9455C4.2985 16.8755 4.29366 16.8187 4.29366 16.7623C4.29366 14.5599 4.2941 12.3575 4.29102 10.1551C4.29102 10.0385 4.32007 9.96891 4.42704 9.90376C5.89032 9.01364 7.58912 9.14835 8.88379 10.2819C9.12987 10.4976 9.38036 10.5949 9.707 10.5883C10.5267 10.5716 11.3468 10.5769 12.1665 10.5848C12.9386 10.5918 13.4369 11.2887 13.1878 11.9979C13.0408 12.4161 12.6481 12.6872 12.1678 12.6903C11.5119 12.6947 10.856 12.6916 10.2 12.6916C9.47368 12.6916 8.74776 12.689 8.02141 12.693C7.61377 12.6952 7.36064 13.0421 7.49315 13.4052C7.57063 13.6174 7.75948 13.7446 8.01524 13.7455C8.63595 13.7477 9.25665 13.7464 9.8778 13.7464C10.7327 13.7464 11.588 13.7473 12.4429 13.7464C12.8532 13.746 13.1878 13.5923 13.4484 13.2657C14.3249 12.1674 15.2084 11.0743 16.0875 9.97772C16.3631 9.63391 16.7086 9.4653 17.1497 9.55423C17.5961 9.64403 17.8651 9.92577 17.9729 10.3655C17.9765 10.381 17.9919 10.3933 18.002 10.4069V10.7234L18.0007 10.723Z"
                      fill={color}/>
                  <path
                      d="M0.390478 18.0002C0.0880497 17.8584 -0.000873973 17.6181 6.45927e-06 17.2914C0.00881078 14.5744 0.00484884 11.8574 0.00484884 9.14083C0.00484884 8.65835 0.18974 8.47214 0.673537 8.47434C1.05388 8.4761 1.43731 8.45453 1.8137 8.49547C2.65099 8.58704 3.22855 9.21391 3.23339 10.0516C3.24528 12.177 3.24572 14.3028 3.23339 16.4282C3.22855 17.2324 2.68048 17.8391 1.89602 17.9746C1.87401 17.9786 1.85332 17.9914 1.83219 18.0002H0.390478Z"
                      fill={color}/>
                  <path
                      d="M10.0932 2.44342C9.33252 3.14512 8.58812 3.83186 7.84415 4.51859C6.90605 5.3845 5.96883 6.25129 5.02985 7.11631C4.57643 7.53408 3.93239 7.51471 3.52123 7.07449C3.12151 6.64616 3.1444 5.99816 3.59562 5.58216C4.99507 4.29232 6.40112 3.00953 7.80497 1.7241C8.30594 1.2654 8.80778 0.807132 9.30787 0.347986C9.81148 -0.114241 10.3728 -0.115121 10.8724 0.344024C12.6839 2.00804 14.4976 3.66942 16.306 5.33652C16.4975 5.51304 16.7004 5.69881 16.8263 5.92112C17.0706 6.35253 16.9262 6.91293 16.5472 7.19819C16.1339 7.50898 15.5466 7.49005 15.1724 7.14448C13.7787 5.85685 12.3872 4.56702 10.9948 3.27762C10.699 3.00381 10.4027 2.73 10.0928 2.44342H10.0932Z"
                      fill={color}/>
                  <path
                      d="M11.6741 9.53925C11.6741 9.01231 11.675 8.52103 11.6741 8.02974C11.6732 7.62695 11.4707 7.42049 11.0723 7.41961C10.4221 7.41784 9.77193 7.41828 9.12173 7.41961C8.71365 7.42049 8.51247 7.62563 8.51115 8.03987C8.51027 8.27362 8.51115 8.50738 8.51115 8.76447C7.49821 8.27803 6.4593 8.1376 5.37593 8.40085C5.30462 8.28463 5.38253 8.22696 5.45165 8.16357C6.35145 7.33332 7.25081 6.50308 8.15017 5.67239C8.79113 5.08074 9.43252 4.48865 10.0876 3.88379C10.1342 3.92385 10.1778 3.95951 10.2192 3.9978C11.7199 5.38625 13.2193 6.77557 14.7222 8.16137C14.8071 8.23973 14.8415 8.31853 14.8397 8.43342C14.8335 8.86087 14.8415 9.28876 14.8335 9.71621C14.8322 9.79589 14.7988 9.88746 14.7503 9.95085C14.5492 10.2159 14.3365 10.4721 14.1164 10.7468C13.5921 9.75407 12.7399 9.44416 11.6745 9.53969L11.6741 9.53925Z"
                      fill={color}/>
                  <path
                      d="M15.8906 3.52793C15.5675 3.23079 15.2761 2.96269 14.9851 2.69416C14.2843 2.04748 13.5839 1.40081 12.8826 0.75413C12.7035 0.588608 12.6828 0.438935 12.8192 0.238637C12.9258 0.0823598 13.0776 0.00312085 13.2652 0.00224042C13.9612 0.000479555 14.6576 -0.00216174 15.3536 0.00312085C15.6604 0.00532193 15.8867 0.219267 15.8884 0.516413C15.8946 1.5047 15.8906 2.49298 15.8906 3.52749V3.52793Z"
                      fill={color}/>
                  <path
                      d="M9.56555 8.48096H10.6075V9.52735C10.2946 9.52735 9.98112 9.53175 9.66856 9.52163C9.63291 9.52031 9.57128 9.43887 9.56996 9.39309C9.56115 9.09594 9.56555 8.79835 9.56555 8.48096Z"
                      fill={color}/>
              </g>
              <defs>
                  <clipPath id="clip0_5268_1552">
                      <rect width={size} height={size} fill="white"/>
                  </clipPath>
              </defs>
          </svg>


      </>
  );
};
