import { authorized } from '../axios';
import {
  GET_BANKS,
  GET_BENEFICIARIES,
  STORE_ACCOUNT,
  VALIDATE_ACCOUNT,
  VERIFY_WITHDRAW,
  WITHDRAW_FUND,
} from '../service-routes';
import { StoreAccount, ValidateAccount, Withdraw } from '../constant/serviceType';

export const validateAccount = (payload: ValidateAccount) => {
  return authorized.post(VALIDATE_ACCOUNT, payload);
};

export const getBanksData = () => {
  return authorized.get(GET_BANKS);
};

export const getBeneficiaries = (id: string | number) => {
  return authorized.get(`${GET_BENEFICIARIES}/${id}`);
};

export const storeAccount = (payload: StoreAccount) => {
  return authorized.post(STORE_ACCOUNT, payload);
};

export const withdrawWallet = (payload: Withdraw) => {
  return authorized.post(WITHDRAW_FUND, payload);
};

export const verifyWithdraw = (reference: string ) => {
  return authorized.get(`${VERIFY_WITHDRAW}/${reference}`);
};

