import React, { useEffect, useRef, useState } from 'react';
// import { ArrowCircleDown2, ArrowCircleLeft, ArrowCircleRight, ArrowUp,ArrowCircleUp2, House2, User } from 'iconsax-react';
import { ArrowCircleLeft, ArrowCircleRight } from 'iconsax-react';
// import propertyImage from '../../Assets/Images/propertyImage.png';
import { ReactComponent as Explore } from '../../Assets/Images/homesale.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import { getDashboardData } from '../../Services/hooks/Dashboard';
import { ObjectType } from '../../Services/constant/serviceType';
import { formatCurrency } from '../../Utils/currencyFormat';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';

function Dashboard() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const [dashboardData, setDashboardData] = useState<ObjectType>({});
  console.log('dashboard', dashboardData);
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = useRef({
    className: 'flex fle ',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    afterChange: (index: any) => setCurrentSlide(index),
    nextArrow: <ArrowCircleRight size="35" color={currentSlide === dashboardData?.investments?.length - 4 ? '#8E8E93' : '#286722' } variant="Bold" />,
    prevArrow: <ArrowCircleLeft size="35" color={currentSlide === 0 ? '#8E8E93' : '#286722' } variant="Bold" />,
  });
  const size = useRef(40);
  const sliderSettings = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 540) {
      size.current = 20;
      settings.current = {
        ...settings.current,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    } else if (screenWidth <= 820) {
      size.current = 20;
      settings.current = {
        ...settings.current,
        slidesToShow: 2,
        slidesToScroll: 2,
      };
    } else if (screenWidth <= 1024) {
      size.current = 20;
      settings.current = {
        ...settings.current,
        slidesToShow: 3,
        slidesToScroll: 3,
      };
    }
  };
  sliderSettings();

  const dash = async () => {
    const response = await getDashboardData(user.id);
    console.log(response);
    setDashboardData(response.result);
  };

  useEffect(() => {
    dash();
  }, []);
  return (
    <div className='overflow-scroll overflow-x-hidden'>
      <div className='w-11/12 md:w-11/12 lg:w-11/12 mx-auto  py-7 '>
        <h1 className='font-semibold'>Dashboard</h1>
        <div className={'flex w-full md:w-11/12 mx-auto py-10 gap-7 xl:flex-row flex-col'}>
          <div className={'w-full xl:w-6/12 rounded-xl bg-[#A6D6C4] flex items-center py-0 p-7 relative h-44'}>
            <div className={'w-full sm:w-1/3'}>
              <h1 className={'text-2xl pb-2.5 sm:pb-1.5 font-bold'}>Enjoy your <br className={'hidden sm:block'}/> first home sale</h1>
              <Link to={'/app/properties'} className={'bg-black rounded-xl py-2 px-5 text-white'}>Explore Now</Link>
            </div>
            <div className={'sm:block hidden absolute w-2/3 bottom-0 right-0'}>
              <Explore />
            </div>
          </div>
          <div className={'flex xl:w-5/12 w-full'}>
            <div className={'w-[40%] rounded-xl bg-[#A7E0DA] flex-col flex justify-between p-4'}>
              <h1>
                Property Value
              </h1>
              <div>
                <h1 className={'text-sm md:text-lg font-bold pb-1.5'}>
                  {formatCurrency(dashboardData?.property_value || 0) || 0}
                </h1>
                {/*<div className={'flex gap-1 items-center text-sm'}>*/}
                {/*  <ArrowUp size="14" color="#000000"/>*/}
                {/*  <p>*/}
                {/*    105.23 %*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={'w-[30%] rounded-xl bg-[#E1F8FF] flex-col flex justify-between p-4'}>
              <h1>
                Properties
              </h1>
              <div>
                <h1 className={'text-sm md:text-lg font-bold pb-1.5'}>
                  {dashboardData?.properties_count || 0}
                </h1>
                {/*<div className={'flex gap-1 items-center text-sm'}>*/}
                {/*  <ArrowUp size="14" color="#000000"/>*/}
                {/*  <p>*/}
                {/*    105.23 %*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={'w-[30%] rounded-xl bg-[#FBEBB8] flex-col flex justify-between p-4'}>
              <h1>
                Co-owned
              </h1>
              <div>
                <h1 className={'text-sm md:text-lg font-bold'}>
                  {dashboardData?.properties_count || 0}
                </h1>
                {/*<div className={'flex gap-1 items-center text-sm'}>*/}
                {/*  <ArrowUp size="14" color="#000000"/>*/}
                {/*  <p>*/}
                {/*    105.23 %*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* recent investment */}
      <div className='w-11/12 md:w-11/12 lg:w-11/12 mx-auto '>
        <h1 className='font-semibold'>My recent investment</h1>
        <div className='w-11/12 md:w-11/12 xl:w-11/12 mx-auto mt-7'>
          {dashboardData?.investments?.length === 0 || !dashboardData ?
              <div className={'text-center text-custom py-7'}>
                No Recent Investment
              </div> :
              <Slider {...settings.current} >
                {dashboardData?.investments?.map((investment: any, index: number) => {
                  return (
                      <div key={index} className='rounded-3xl overflow-hidden w-fit bg-white border border-custom-grey'>
                        <div className='relative'>
                          <img src={investment?.investment?.property?.thumbnail} alt='investment' className='w-full h-44 bg-green-200'/>
                          <span
                              className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-2.5 ${investment?.investment?.property?.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                      {investment?.investment?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                    </span>
                        </div>
                        <div className='p-3'>
                          <h1 className='py-2'>{investment?.investment?.property?.details?.property_title}</h1>
                          <div className={'flex gap-2 py-2'}>
                    <span
                        className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Bed/>
                      </span>
                      <span>{investment?.investment?.property?.details?.rooms_no || 0}</span>
                    </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Shower/>
                      </span>
                      <span>{investment?.investment?.property?.details?.showers || 0}</span>
                    </span>
                            <span
                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                      <span>
                        <Toilet/>
                      </span>
                      <span>{investment?.investment?.property?.details?.toilets_no || 0}</span>
                    </span>
                          </div>
                          <p className='font-bold text-lg pb-2.5'>{formatCurrency(investment?.investment?.property?.total_investment_amount)}</p>
                          <p className='text-xs pb-2.5 text-custom'>{investment?.investment?.property?.location}</p>
                          <div className='flex justify-between text-xs pb-1'>
                            <span>No Of Slots Available:</span>
                            <span
                                className={`font-semibold ${investment?.investment?.property?.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                        {investment?.investment?.property?.available_slots === 0 ? 'Sold Out' : `${investment?.investment?.property?.available_slots} Slots`}
                      </span>
                          </div>
                          <div className='flex justify-between gap-1 pb-1.5'>
                            {Array.from({ length: investment?.investment?.property?.available_slots }).map((_, number) => (
                                <hr key={number}
                                    className={`h-2 w-8 rounded-full ${number < (investment?.investment?.property?.total_slots - investment?.investment?.property?.available_slots) ? 'bg-custom-darkgreen' : 'bg-custom'}`}/>
                            ))}
                          </div>
                          <div className='flex justify-between text-xs pb-2.5'>
                            <span>Price Per Slot:</span>
                            <span
                                className={'font-semibold'}>{formatCurrency(investment?.investment?.property?.total_investment_amount)}</span>
                          </div>
                          {/*<div className='flex justify-between text-xs pb-6'>*/}
                          {/*  <span>Annual Earnings:</span>*/}
                          {/*  <span className={'font-semibold'}>{investment.annualEarning}</span>*/}
                          {/*</div>*/}
                          <div className='flex justify-end'>
                            <Link to={`/app/properties/${investment?.investment?.property?.id}`}
                                  className='bg-custom-midnightgreen px-7 py-1 rounded-full text-white text-sm'>
                              View
                            </Link>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </Slider>}

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
