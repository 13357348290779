import React, { useEffect, useState } from 'react';
import { ArrowCircleLeft2 } from 'iconsax-react';
import InputField from '../Form/InputField';
import ReviewTransaction from './ReviewTransaction';
import { formatCurrency } from '../../Utils/currencyFormat';
import { usePayment } from '../../Services/hooks/payment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import { PostPayment } from '../../Services/constant/serviceType';
import CustomButton from '../Form/CustomButton';
import PaymentFrame from '../Modal/PaymentFrame';
import { useWallet } from '../../Services/hooks/wallet';

function BuyProperty(props: any) {
  const location = useLocation();
  let pageName = location.pathname;
  pageName = pageName.split('/').pop() || '';
  console.log(props);
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { getTransactionFee, loading: isLoading } = useWallet();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setError] = useState<string | null>(null);
  const { verifyPayment, postPayWithWallet } = usePayment();
  const [amount, setAmount] = useState<number>(props.investmentDetails?.property?.total_investment_amount || 0);
  const [slot, setSlot] = useState<number>(0);
  const [review, setReview] = useState(false);
  const handleAmountChange = (e: any) => {
    console.log(props.investmentDetails.results.property.available_slots);
    if ( e.target.value > props.investmentDetails.results.property.available_slots) {
      setError('Number grater than available slots');
    } else {
      setError('');
      setSlot(e.target.value);
      const price : any = e.target.value * props.investmentDetails.results.property.total_investment_amount /
          props.investmentDetails.results.property.total_slots || 0;
      setAmount(price);
    }

  };
  const handleSuggestSlotChange = (newSuggestSlot: number) => {
    console.log(props.investmentDetails.results.property.available_slots);
    if ( newSuggestSlot > props.investmentDetails.results.property.available_slots) {
      setError('Number grater than available slots');
    } else {
      setError('');
      setSlot(newSuggestSlot);
      const price : any = newSuggestSlot * props.investmentDetails.results.property.total_investment_amount /
            props.investmentDetails.results.property.total_slots || 0;
      setAmount(price);
    }
  };
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [responseIframe] = useState<any>(null);
  const handleSubmit = async () => {
    // e.preventDefault();
    console.log(pageName);
    const data: PostPayment = {
      'slot': slot,
      'amount': props.investmentDetails?.results?.property?.total_investment_amount,
      // 'total_amount': amount,
      'total_amount': 5000,
      'user_id': user.id,
      'property_id' : Number(pageName),
      // 'property_id': 2,

    };
    const response = await postPayWithWallet(data);
    console.log('tyu', response);


  };
  const handleVerifyPayment = async () => {
    const response = await verifyPayment(responseIframe?.results.reference);
    console.log(response);
    if (response) {
      navigate('/app/myProperties');
    }
  };
  const handleReview = async (e: any) => {
    e.preventDefault();
    const fee = {
      slots: slot,
      amount: amount,
    };
    const { success, results } = await getTransactionFee(fee);
    console.log(results);
    if (success) {
      setReview(true);
      const data = {
        'slot': slot,
        'amount': props.investmentDetails?.results?.property?.total_investment_amount,
        'total_amount': amount,
        'processing_fee': results.processing_fee,
        'user_id': user.id,
        'property_id': Number(pageName),

      };
      const postPaymentData = {
        'slot': slot,
        'amount': props.investmentDetails?.results?.property?.total_investment_amount,
        'total_amount': amount,
        // 'processing_fee': results.processing_fee,
        'user_id': user.id,
        'property_id': Number(pageName),

      };
      setPaymentData(postPaymentData);
      setReviewData(data);
    }

  };
  useEffect(() => {
    if (props.investmentDetails) {
      setAmount(props.investmentDetails.results.property.total_investment_amount /
          props.investmentDetails.results.property.total_slots || 0);
    }
  }, []);
  return (
      <div>
        <div className='w-11/12 mx-auto'>
          <div className='flex items-center gap-2 py-4 cursor-pointer'>
            <ArrowCircleLeft2 size="42" variant="Bold" color="#20996B" onClick={() => props.onHide()}/>
          </div>
          {!review ?
              <div
                  className=' xl:w-10/12  mx-auto py-6 px-4 flex  md:flex-row flex-col-reverse gap-5'>
                <div className='w-full flex bg-white rounded-3xl flex-col items-center py-9 ' style={{ boxShadow: '0px 4px 20px 0px #5E84C20F' }}>
                  <div className='py-9 px-10 rounded-3xl w-full' >
                    <h1 className='text-2xl font-bold py-4'>
                      How many slots are you buying?
                    </h1>
                    <div className=''>
                  <span className='text-sm'>
                    Suggested for you
                  </span>
                      <div className='flex flex-wrap gap-3 my-3'>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(1)}
                                style={{ backgroundColor: '#C0F0FF' }}>
                          1 Slot
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(2)}
                                style={{ backgroundColor: '#C0F0FF' }}>
                          2 Slots
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(3)}
                                style={{ backgroundColor: '#FADAFF' }}>
                          3 Slots
                        </button>
                        <button type='button' className='rounded-full py-2 px-6 text-xs'
                                onClick={() => handleSuggestSlotChange(4)}
                                style={{ backgroundColor: '#D1FFE1' }}>
                          4 Slots
                        </button>
                      </div>
                      <p className='text-sm py-3 font-bold'>Price Per Slot {
                        formatCurrency(props.investmentDetails?.results?.property?.total_investment_amount &&
                        props.investmentDetails?.results?.property?.total_slots
                          ? props.investmentDetails.results.property.total_investment_amount /
                            props.investmentDetails.results.property.total_slots
                          : 'N/A')
                      }
                      </p>
                      <form onSubmit={handleReview}>
                        <div className='py-5'>
                          <div className='py-3'>
                            <InputField name='Enter Slot' type='text' className='w-3/4' value={slot}
                                        onChange={handleAmountChange}/>
                            <p className={'text-red-900 italic text-sm py-1 '}>{errorMessage}</p>
                          </div>
                          <div className='py-3'>
                            <InputField name='Amount' readOnly={true} type='text' className='w-full'
                                        value={formatCurrency(amount)}/>
                          </div>
                        </div>
                        <div className='my-4'>
                          {/*<button type='button'*/}
                          {/*  disabled={slot === 0}*/}
                          {/*  className={`${slot ? '' : 'opacity-60'} bg-custom-midnightgreen w-full py-3 text-white rounded-custom font-bold px-6 text-xs`}*/}
                          {/*  onClick={() => {*/}
                          {/*    setReview(true);*/}
                          {/*  }}>*/}
                          {/*    Continue*/}
                          {/*</button>*/}
                          <CustomButton
                              isLoading={isLoading}
                              disabled={!slot}
                              children={'Continue'}
                              className={`${slot ? '' : 'opacity-60'} bg-custom-midnightgreen w-full py-3 text-white rounded-custom font-bold px-6 text-xs`}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                    className={'w-full md:w-5/12 px-5 py-2 pt-10 pb-14 rounded-2xl bg-white flex-col justify-center flex items-center h-fit gap-y-5'}>
                  <div className={'flex flex-col gap-y-2.5'}>
                    <p className={'text-sm font-light text-center'}>
                      Property Value
                    </p>
                    <p className='font-bold text-sm text-center md:text-xl lg:text-2xl'>{formatCurrency(props.investmentDetails?.results?.property?.total_investment_amount)}</p>
                    {/*<p className='text-center text-xxs lg:text-sm text-custom-lemongreen'>Annual*/}
                    {/*  Return:₦6,000.000.00</p>*/}
                  </div>
                  <div className={'w-full'}>
                    <div
                        className={`w-full  rounded-full h-2 ${props.investmentDetails?.results?.property?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC26733]'}`}
                        style={{ mixBlendMode: 'multiply' }}>
                      <div
                          className={` h-2 rounded-full ${props.investmentDetails?.results?.property?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC267]'}`}
                          style={{ width: `${props.investmentDetails?.results?.property?.percent_funded}%` }}></div>
                    </div>
                    <div
                        className={'flex justify-between w-full text-xxs text-custom-darkgreen pt-0.5'}>
                      <p>
                        {formatCurrency(props.investmentDetails?.results?.property?.amount_invested)} Invested
                      </p>
                      <p>
                        {`${props.investmentDetails?.results?.property?.available_slots} ${props.investmentDetails?.results?.property?.available_slots <= 1 ? 'slot' : 'slots'}`} remaining
                      </p>
                    </div>
                  </div>
                  <div className={'w-full flex flex-col gap-y-5'}>
                    <div className={'bg-[#F9F9F9] p-3 py-6 rounded-2xl flex flex-col gap-y-3 w-full'}>
                      <div className='flex justify-between text-sm'>
                        <span>10 years total return</span>
                        <span
                            className={'font-semibold text-[#5E6473]'}>{props.investmentDetails?.results?.property?.ten_year_total_return || 0}%</span>
                      </div>
                      <div className='flex justify-between text-sm'>
                        <span>Yearly investment return</span>
                        <span
                            className={'font-semibold text-[#5E6473]'}>{props.investmentDetails?.results?.property?.yearly_investment_return || 0}%</span>
                      </div>
                      <div className='flex justify-between text-sm'>
                        <span>Projected net yield</span>
                        <span
                            className={'font-semibold text-[#5E6473]'}>{props.investmentDetails?.results?.property?.projected_net_yield || 0}%</span>
                      </div>
                    </div>
                  </div>

                </div>

              </div> :
              <ReviewTransaction reviewData={reviewData} paymentData={paymentData} submit={handleSubmit}/>
          }
        </div>
        <div>
          <PaymentFrame isOpen={isOpen} onHide={() => setIsOpen(false)} src={responseIframe?.results?.link}
                        verify={handleVerifyPayment}/>
        </div>

      </div>
  );
}

export default BuyProperty;
