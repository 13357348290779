export function formatCurrency(amount: number | any) {
  return `₦${amount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

export const formatCurrency2 = (value: string | number) => {
  let numberValue = typeof value === 'string' ? value : value.toString();

  // Remove any existing ₦ signs or non-numeric characters except digits
  numberValue = numberValue.replace(/[₦,]/g, '');

  // Convert to a number and format with commas (if needed)
  const formattedValue = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0, // You can adjust fraction digits here if needed
  }).format(Number(numberValue));

  return formattedValue; // This will include the ₦ symbol automatically
};


