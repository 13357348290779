import { postLogin } from '../services/authservices';
import { Login } from '../constant/serviceType';
import { NavigateFunction } from 'react-router-dom';
import { showToast } from '../../Storeredux/constants/toastAction';
// import { useToast } from '../../Store/NotificationContext';

export const loginUser = async (payload: Login, navigate: NavigateFunction) => {
  let userResponse = null;
  // const { showToast } = useToast();
  try {
    const response = await postLogin(payload);
    userResponse = response?.data?.data;
    console.log(userResponse);
    if (userResponse) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const access_token = 'verified';
      navigate('/app/properties');
      return { success: true, data: access_token, user: userResponse };
    }
  } catch (e: any) {
    console.log('gdgdgd', e);
    showToast('Error', e.response.data.message, 'error');
    return { success: false, error: e.response.data.message };
  }

  return userResponse;
};
