import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  handleLoginRequest,
  handleLoginSuccess,
  handleLoginFailure,
  handleLogout,
  handleRegisterRequest,
  handleRegisterSuccess,
  handleSuccess,
  handleFailure, handleForgotPassword, handleStart,
} from '../actions';
import { Auth } from '../constants/actionTypes';

const initialState: Auth = {
  token: null,
  isAuthenticated: true,
  loading: false,
  error: null,
  success: false,
};

const authReducer = createReducer(initialState, (builder: any) => {
  builder
    .addCase(handleLoginRequest, (state: Auth) => {
      state.loading = true;
    })
    .addCase(handleLoginSuccess, (state: Auth, action: PayloadAction<{ token: string }>) => {
      console.log('handleLoginSuccess', action.payload );
      state.loading = false;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.error = null;
    })
    .addCase(
      handleLoginFailure,
      (state: Auth, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
    )
    .addCase(handleLogout, (state: Auth) => {
      state.isAuthenticated = false;
      state.token = null;
      state.error = null;
      state.loading = false;
    })
    .addCase(handleRegisterRequest, (state: Auth) => {
      state.loading = true;
    })
    .addCase(handleForgotPassword, (state: Auth) => {
      state.loading = true;
    })
    .addCase(handleRegisterSuccess, (state: Auth, action: PayloadAction<{ token: string }>) => {
      console.log('reducer', action.payload);
      state.loading = false;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.error = null;
    })
    .addCase(handleSuccess, (state: Auth) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    })
    .addCase(handleStart, (state: Auth) => {
      state.success = false;
    })
    .addCase(handleFailure, (state: Auth, action: PayloadAction<string>) => {
      console.log('reducer failure', action.payload);
      state.loading = false;
      console.log('reducer', action.payload);
      state.error = action.payload;
    });
});

export default authReducer;
