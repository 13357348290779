import React, { useState } from 'react';
import { ReactComponent as UserIcon } from '../../../Assets/Icons/usernamicon.svg';
import { ReactComponent as EmailIcon } from '../../../Assets/Icons/Emailiconwhite.svg';
import { ReactComponent as PasswordIcon } from '../../../Assets/Icons/passwordicon.svg';
import { ReactComponent as AddressIcon } from '../../../Assets/Icons/addressIcon.svg';
import { ReactComponent as PhoneIcon } from '../../../Assets/Icons/phoneicon.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as HomeAbstract } from '../../../Assets/Images/homeabstract.svg';
import OnboardingKyc from '../OnboardingKyc';
import OtpVerification from '../OtpVerification';
import CacVerification from '../CacVerification';

function CooperateSignUp() {
  const [data, setData] = useState({
    'name' : '',
    'email' : '',
    'password' : '',
    're_password' : '',
    'bvn':'',
    'phone':'',
    'first_name':'',
    'last_name':'',
  });
  const [passwordError, setPasswordError] = useState<string>('');
  const setOnChange = (e: any) => {
    if (e.target.name === 're_password') {
      if (e.target.value === data.password) {
        setData({ ...data, re_password: e.target.value });
        setPasswordError('');
      } else {
        setData({ ...data, re_password: '' });
        setPasswordError('Passwords do not match');
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const [next, setNext] = useState('');

  const submitRegister = () => {
    // e.preventDefault()
    // console.log(data)
    // register(data)
    setNext('kyc');
  };
  return (
    <div>
      <div className='relative overflow-hidden' style={{ height: '90vh' }}>
        <div className='w-full hidden md:flex'>
          <HomeAbstract className='abstract'/>
        </div>
        <div className=' absolute top-0 h-screen flex flex-col items-center w-full'
          style={{ backgroundColor: '#0000000D' }}>
          <div
            className={`${next ? 'hidden' : ''} bg-white rounded-xl md:h-3/4 xl:h-fit md:w-6/12 lg:w-5/12 xl:w-4/12 w-11/12 overflow-auto border border-input-color my-8`}
            style={{ boxShadow: '0px 2px 8px 2px #8C8C8C1A' }}>

            <form autoComplete="off" className='p-7 bg-white' onSubmit={submitRegister}>
              <div className='flex justify-between'>
                <h3 className='font-semibold text-xl'>
                                    Register
                </h3>
              </div>
              <div className={'flex gap-3.5'}>
                <div className='py-4 w-[50%]'>
                  <label className='font-semibold text-sm block pb-2'>
                                        First name
                  </label>
                  <div className="relative">
                    <UserIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='name' onChange={setOnChange} required
                      className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                      type="text" placeholder="Enter your firstname"/>
                  </div>
                </div>
                <div className='py-4 w-[50%]'>
                  <label className='font-semibold text-sm block pb-2'>
                                        Last name
                  </label>
                  <div className="relative">
                    <UserIcon
                      className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                    <input autoComplete='off' name='name' onChange={setOnChange} required
                      className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                      type="text" placeholder="Enter your lastname"/>
                  </div>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Company name
                </label>
                <div className="relative">
                  <UserIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input autoComplete='off' name='name' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="text" placeholder="Enter your lastname"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Company address
                </label>
                <div className="relative">
                  <AddressIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input autoComplete='off' name='name' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="text" placeholder="Enter your lastname"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Email
                </label>
                <div className="relative">
                  <EmailIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input autoComplete='off' name='email' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="email" placeholder="Email"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Phone Number
                </label>
                <div className="relative">
                  <PhoneIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='phone' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="text" placeholder="Phone Number"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Password
                </label>
                <div className="relative">
                  <PasswordIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='password' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="password" placeholder="Password"/>
                </div>
              </div>
              <div className='pb-3'>
                <label className='font-semibold text-sm block pb-2'>
                                    Confirm Password
                </label>
                <div className="relative">
                  <PasswordIcon
                    className="absolute w-4 h-4 text-gray-500 left-2 top-1/2 transform -translate-y-1/2"/>
                  <input name='re_password' onChange={setOnChange} required
                    className="focus-visible:outline-0 text-xs w-full p-2 pl-10 border border-input-color rounded-custom"
                    type="password" placeholder="Password"/>
                </div>
                <div className='pb-3'>
                  {passwordError && <p className='text-red-500 text-xs italic'>{passwordError}</p>}
                </div>
              </div>
              <div className='flex justify-center'>
                <button onClick={submitRegister}
                  className='w-6/12 bg-custom-darkgreen text-white font-semibold rounded-custom py-2'>
                                    Register
                </button>
              </div>
              <div className='text-center py-3 text-xs'>
                <p>Got an account? <Link to='/login'
                  className='font-semibold text-custom-green'>Login</Link></p>
              </div>
            </form>
          </div>
          <OnboardingKyc next={next} proceed={(props: string) => setNext(props)} />
          <CacVerification next={next} proceed={(props: string) => setNext(props)} />
          <OtpVerification next={next} />
        </div>
      </div>
    </div>
  );
}

export default CooperateSignUp;
