import { getUserData, postUserData } from '../services/userService';
import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';
import { UserData } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';
import { handleSaveUserData } from '../../Storeredux/actions';
import { useDispatch } from 'react-redux';

export const useUserData = () => {
  const [nextOfKinData] = useState<User>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const updateUserData = async (payload: UserData, id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response: any = await postUserData(payload, id);
      userResponse = response?.data?.data;
      if (userResponse) {
        setLoading(false);
        dispatch(handleSaveUserData(userResponse));
        showToast('Success', response.data.message, 'success');
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, response: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getUserInfo = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    try {
      const response = await getUserData(id);
      userResponse = response?.data?.data;
      if (userResponse) {
        dispatch(handleSaveUserData(userResponse));
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, response: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };

  return { getUserInfo, updateUserData,  loading, nextOfKinData };
};
