import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useProperty } from '../../Services/hooks/PropertyCoOwn';
import { PageLoader } from '../../Components/Loader/pageLoader';
import { User, userData } from '../../Storeredux/constants/actionTypes';
import { formatCurrency } from '../../Utils/currencyFormat';
import { ReactComponent as Bed } from '../../Assets/Icons/bedcardIcon.svg';
import { ReactComponent as Shower } from '../../Assets/Icons/ShowerIcon.svg';
import { ReactComponent as Toilet } from '../../Assets/Icons/toiletIcon.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as Caution } from '../../Assets/Icons/lightcaution.svg';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { shortenSentence } from '../../Utils/shorttenText';

function MyProperties() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const { getUserPropertyData, loading: pageLoading, property, others } = useProperty();
  // const calculateFilledPercentage = (investment: any) => {
  //
  //   if (investment?.property.total_slots && investment?.property.available_slots !== undefined) {
  //     const filledSlots = investment?.property.total_slots - investment?.property.available_slots;
  //     return (filledSlots / investment?.property.total_slots) * 100;
  //   }
  //
  //   return 0;
  // };
  const screenWidth = window.innerWidth;
  useEffect(() => {
    getUserPropertyData(user.id);
  }, []);
  return (
        <div className='py-9 pt-3'>
            <div className='rounded-3xl w-11/12 mx-auto py-4 '>
                <div className={`flex w-full gap-3 lg:gap-5 ${screenWidth <= 390 ? 'flex-col ' : 'flex-row'}`}>
                    <div className={`bg-white rounded-2xl py-3 px-2.5 lg:px-5 border flex flex-col gap-8 border-custom-grey ${screenWidth <= 390 ? 'w-full ' : 'w-1/3'}`}>
                        <div className={'flex items-center gap-3 font-light text-sm text-custom-darkgreen'}>
                            <p>{others.total_properties <= 1 ? 'My Property' : 'My Properties'}</p>
                            <Caution/>
                        </div>
                        <p className={'font-semibold text-custom-darkgreen'}>{others.total_properties}</p>
                    </div>
                    <div
                        className={`bg-white rounded-2xl py-3 px-2.5 lg:px-5 border flex flex-col gap-8 border-custom-grey ${screenWidth <= 390 ? 'w-full ' : 'w-1/3'}`}>
                        <div className={'flex items-center gap-3 font-light text-sm text-custom-darkgreen'}>
                            <p>Value Of Slot</p>
                            <Caution/>
                        </div>
                        <p className={'font-semibold text-custom-darkgreen'}>₦{others.value_of_slot}</p>
                    </div>
                    <div
                        className={`bg-white rounded-2xl py-3 px-2.5 lg:px-5 border flex flex-col gap-8 border-custom-grey ${screenWidth <= 390 ? 'w-full ' : 'w-1/3'}`}>

                        <div className={'flex items-center gap-3 font-light text-sm text-custom-darkgreen'}>
                            <p>Earnings</p>
                            <Caution/>
                        </div>
                        <p className={'font-semibold text-custom-darkgreen'}>₦{others.earnings}</p>
                    </div>
                </div>
                <div className='py-10 pb-3'>
                    <h1 className='font-semibold text-xl'>
                        My Investment
                    </h1>
                </div>
                {pageLoading ?
                    <div className='gap-7 w-fit justify-center mx-auto mt-7 flex flex-wrap h-52'>
                        <PageLoader />
                    </div>
                  :
                    <div className={`gap-3 w-fit mx-auto mt-7 flex flex-wrap ${screenWidth <= 390 ? 'justify-center' : ''}`}>
                        {property?.map((investment: User, index) => {
                          return (
                                <Link to={`/app/properties/${investment?.property?.id}`} key={index}
                                      className={`rounded-3xl overflow-hidden lg:w-[255px] xl:w-[285px] md:w-[225px] bg-white border border-custom-grey ${screenWidth <= 390 ? 'w-[285px]' : 'w-[240px]'}`}>
                                    <div className='relative'>
                                        <img src={investment?.property.thumbnail} alt='investment'
                                             className='w-full h-44 bg-green-200'/>
                                        <span
                                            className={`absolute uppercase bg-white top-2 font-semibold left-2 rounded-full text-xs py-1 px-3 ${investment?.property?.available_slots === 0 ? 'text-red-500' : 'text-green-600'}`}>
                                          {investment?.property?.available_slots === 0 ? 'Sold Out' : 'Active'}
                                        </span>
                                    </div>
                                    <div className='p-3 pb-4 flex flex-col gap-y-3'>
                                        <div className={'flex gap-2 '}>
                          <span
                              className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Bed/>
                            </span>
                            <span>{investment?.property?.details?.rooms_no}</span>
                          </span>
                                            <span
                                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Shower/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                                            <span
                                                className={'bg-custom-grey flex justify-between gap-3 py-1 px-2.5 w-fit rounded-3xl items-center text-xs'}>
                            <span>
                              <Toilet/>
                            </span>
                            <span>{investment?.property?.details?.toilets_no}</span>
                          </span>
                                        </div>
                                        <h1 className=''>{shortenSentence(investment?.property?.details?.property_title)}</h1>
                                        <div>
                                            <div
                                                className={`w-full  rounded-full h-2 ${investment?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC26733]'}`}
                                                style={{ mixBlendMode: 'multiply' }}>
                                                <div
                                                    className={` h-2 rounded-full ${investment?.percent_funded === 100 ? 'bg-red-600' : 'bg-[#3AC267]'}`}
                                                    style={{ width: `${investment?.percent_funded}%` }}></div>
                                            </div>
                                            <div
                                                className={'flex justify-between w-full text-xxs text-custom-darkgreen pt-0.5'}>
                                                <p>
                                                    {formatCurrency(investment?.amount_invested?.totalInvestedAmount)} Invested
                                                </p>
                                                <p>
                                                    {`${investment?.property?.available_slots} ${investment?.property?.available_slots <= 1 ? 'slot' : 'slots'}`} remaining
                                                </p>
                                            </div>
                                        </div>
                                        <div className={'bg-[#F9F9F9] p-3 rounded-2xl flex flex-col gap-y-2 w-full'}>
                                            <div className='flex justify-between text-xs'>
                                                <span>Number of Owned slot:</span>
                                                <span
                                                    className={'font-semibold'}>{investment?.slots || 0}</span>
                                            </div>
                                            <div className='flex justify-between text-xs'>
                                                <span>Price Per Slot:</span>
                                                <span
                                                    className={'font-semibold'}>{formatCurrency(investment?.price_per_slot)}</span>
                                            </div>
                                            <div className='flex justify-between text-xs'>
                                                <span>Yearly Investment Return:</span>
                                                <span
                                                    className={'font-semibold'}>{investment?.yearly_investment_return || 0}%</span>
                                            </div>
                                            <div className='flex justify-between text-xs'>
                                                <span>Earnings On Slot:</span>
                                                <span
                                                    className={'font-semibold'}>{formatCurrency(investment?.earning_on_slot || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                          );
                        })}
                    </div>
                }
            </div>
        </div>
  );
}

export default MyProperties;
