import React, { Fragment } from 'react';
import '../../Stylesheets/allproperties.css';

interface TabProps {
  id: string;
  label: string;
  notificationCount?: number;
}

interface TabsProps {
  tabs: TabProps[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
}

const PropertyTabs = ({ tabs, activeTabIndex, onTabChange }: TabsProps) => (
    <div className="Propertytabs">
        {tabs.map((tab, index) => (
            <Fragment key={index}>
                <input type="radio" id={tab.id} name="tabs" />
                <label
                    className={`Propertytab label text-sm font-semibold ${activeTabIndex === 0 && tab.id === 'personal' ? 'text-white' : ''}`}
                    htmlFor={tab.id}
                    onClick={() => onTabChange(index)}
                >
                    {tab.label}
                </label>
            </Fragment>
        ))}
        <span className="Propertyglider" style={{ transform: `translateX(${activeTabIndex * 100}%)` }} />
    </div>
);

export default PropertyTabs;
