import { authorized } from '../axios';
import { GET_INVESTMENT_INFO, PAY_WITH_WALLET, PAYMENT_REQUEST, VERIFY_PAYMENT } from '../service-routes';
import { PostPayment } from '../constant/serviceType';

export const getInvestmentDetails = (id: string | number) => {
  return authorized.get(`${GET_INVESTMENT_INFO}/${id}`);
};

export const postPaymentRequest = (payload: PostPayment) => {
  return authorized.post(PAYMENT_REQUEST, payload);
};

export const verifyPaymentData = (reference: string ) => {
  return authorized.get(`${VERIFY_PAYMENT}/${reference}`);
};

export const payWithWallet = (payload: PostPayment ) => {
  return authorized.post(PAY_WITH_WALLET, payload);
};
