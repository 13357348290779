import { useRef, useState } from 'react';
import { User } from '../../Storeredux/constants/actionTypes';
import {
  getBanksData,
  getBeneficiaries,
  storeAccount,
  validateAccount, verifyWithdraw,
  withdrawWallet,
} from '../services/accountService';
import { StoreAccount, ValidateAccount, Withdraw } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';
import { useDispatch } from 'react-redux';
import { handleSaveBank } from '../../Storeredux/actions';


export const useAccount = () => {
  const [beneficiaryData, setBeneficiaries] = useState([]);
  const [investmentDetails, setInvestmentDetails] = useState<User>();
  const [bank, setBank] = useState<[]>([]);
  const [validateLoading, setValidateLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const didFetch = useRef<boolean>(false);
  const { showToast } = useToast();
  const dispatch = useDispatch();

  const getBanks = async () => {
    if (didFetch.current) return;
    let userResponse = null;
    // setLoading(true);
    try {
      const response = await getBanksData();
      userResponse = response?.data?.data;
      if (userResponse) {
        setBank(userResponse);
        dispatch(handleSaveBank(userResponse));
        setLoading(false);
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const validateAccountInfo = async (payload: ValidateAccount) => {
    if (didFetch.current) return;
    let userResponse = null;
    setValidateLoading(true);
    try {
      const response = await validateAccount(payload);
      console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        setValidateLoading(false);
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setValidateLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const storeAccountInfo = async (payload: StoreAccount) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await storeAccount(payload);
      console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        setLoading(false);
        showToast('Success', response.data.message, 'success');
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      showToast('Error', e.response.data.message, 'error');
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const getBeneficiariesInfo = async (id: string | number) => {
    if (didFetch.current) return;
    let userResponse = null;
    setLoading(true);
    try {
      const response = await getBeneficiaries(id);
      console.log('beneficiary', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setBeneficiaries(userResponse);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const VerifyWithdrawal = async (reference: string ) => {
    if (didFetch.current) return;
    let userResponse = null;
    setValidateLoading(true);
    try {
      const response = await verifyWithdraw(reference);
      // console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setValidateLoading(false);
        showToast('Success', response.data.message, 'success');
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setValidateLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  const WithdrawFunds = async (payload: Withdraw) => {
    if (didFetch.current) return;
    let userResponse = null;
    setValidateLoading(true);
    try {
      const response = await withdrawWallet(payload);
      console.log('WALLET', response);
      userResponse = response?.data?.data;
      if (userResponse) {
        setInvestmentDetails(userResponse);
        await VerifyWithdrawal(userResponse.reference);
        setValidateLoading(false);
        return { success: true, results: userResponse };
      }
    } catch (e: any) {
      console.log(e.response.data.message);
      setValidateLoading(false);
      return { success: false, error: e.response.data.message };
    }

    return userResponse;
  };
  return {
    getBanks,
    WithdrawFunds,
    VerifyWithdrawal,
    validateAccountInfo,
    storeAccountInfo,
    beneficiaryData,
    validateLoading,
    getBeneficiariesInfo,
    bank,
    loading,
    investmentDetails,
  };
};

// export const getBankData = async (id: string | number) => {
//   let userResponse = null;
//   try {
//     const response = await getWallet(id);
//     console.log('wallet...', response);
//     userResponse = response;
//     if (userResponse) {
//       return { success: true, result: response.data.data };
//     }
//   } catch (e: any) {
//     // console.log(e.response);
//     return { success: false, error: e.response?.data?.message };
//   }
//
//   return userResponse;
// };
