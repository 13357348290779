import React from 'react';
// import Navbar from "../Components/Layout/Navbar";
import Signup from '../../Components/SignUp/Signup';

function Register() {
  return (
    <div>
      {/*<Navbar />*/}
      <Signup />
    </div>
  );
}

export default Register;
