import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { Auth } from '../../Storeredux/constants/actionTypes';


export const ProtectedRoutes = () => {
  const AuthState = useSelector((state: RootState) => state.auth as Auth);
  const { isAuthenticated } = AuthState;
  console.log('protected', isAuthenticated);
  return (
    isAuthenticated ? <Outlet/> : <Navigate to='/login'/>
  );
};
