import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { baseURL } from './constant/Action';
import { handleLogout } from '../Storeredux/actions';
import store from '../Storeredux/store';

interface ErrorResponse {
  response: {
    status: number;
  };
}

export const unauthorized = axios.create({
  baseURL: baseURL,
});
export const authorized = axios.create({
  baseURL: baseURL,
});

authorized.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const state = store.getState();
    const { token } = state.auth;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: ErrorResponse) => {
    return Promise.reject(error);
  },
);

authorized.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error: ErrorResponse) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(handleLogout());
    }
    return Promise.reject(error);
  },
);
