import { Icon } from '../../Services/constant/serviceType';

export const MyWalletIcon = ({ size, color }: Icon) => {
  return (
      <>
          <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_5268_1561)">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M16.5684 5.56318C16.5193 5.56029 16.4669 5.55914 16.4114 5.55971H14.2211C12.4271 5.55971 10.8926 6.97196 10.8926 8.81273C10.8926 10.6535 12.428 12.0657 14.2211 12.0657H16.4114C16.4669 12.0663 16.5196 12.0652 16.5693 12.0623C16.938 12.04 17.286 11.8845 17.5486 11.6247C17.8111 11.3649 17.9702 11.0185 17.9963 10.65C17.9998 10.598 17.9998 10.5416 17.9998 10.4896V7.13591C17.9998 7.08386 17.9998 7.02748 17.9963 6.97543C17.9702 6.60698 17.8111 6.26057 17.5486 6.00075C17.286 5.74093 16.9371 5.58542 16.5684 5.56318ZM14.0294 9.6802C14.4908 9.6802 14.8647 9.29158 14.8647 8.81273C14.8647 8.33389 14.4908 7.94526 14.0294 7.94526C13.567 7.94526 13.1931 8.33389 13.1931 8.81273C13.1931 9.29158 13.567 9.6802 14.0294 9.6802Z"
                        fill={color}/>
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M16.4108 13.3668C16.4408 13.3656 16.4707 13.3714 16.4979 13.3839C16.5252 13.3964 16.5492 13.4152 16.5679 13.4387C16.5865 13.4622 16.5994 13.4898 16.6054 13.5192C16.6115 13.5486 16.6105 13.579 16.6025 13.6079C16.429 14.2256 16.1523 14.753 15.709 15.1954C15.0593 15.846 14.2361 16.1331 13.2194 16.2702C12.2305 16.4029 10.9683 16.4029 9.37389 16.4029H7.54179C5.94738 16.4029 4.68434 16.4029 3.6963 16.2702C2.67962 16.1331 1.85639 15.8451 1.20665 15.1963C0.557784 14.5465 0.269784 13.7233 0.132723 12.7066C1.03411e-07 11.7177 0 10.4555 0 8.86112V8.76396C0 7.16955 1.03411e-07 5.90651 0.132723 4.9176C0.269784 3.90092 0.557784 3.07769 1.20665 2.42795C1.85639 1.77908 2.67962 1.49108 3.6963 1.35402C4.68521 1.22217 5.94738 1.22217 7.54179 1.22217H9.37389C10.9683 1.22217 12.2313 1.22217 13.2194 1.35489C14.2361 1.49195 15.0593 1.77995 15.709 2.42882C16.1523 2.87297 16.429 3.39952 16.6025 4.01716C16.6105 4.04611 16.6115 4.07652 16.6054 4.10592C16.5994 4.13532 16.5865 4.16289 16.5679 4.18639C16.5492 4.20988 16.5252 4.22864 16.4979 4.24114C16.4707 4.25364 16.4408 4.25952 16.4108 4.25832H14.2213C11.7603 4.25832 9.59163 6.20145 9.59163 8.81254C9.59163 11.4236 11.7603 13.3668 14.2213 13.3668H16.4108ZM3.25302 4.69205C3.08047 4.69205 2.91498 4.7606 2.79297 4.88261C2.67096 5.00462 2.60241 5.17011 2.60241 5.34266C2.60241 5.51521 2.67096 5.68069 2.79297 5.8027C2.91498 5.92471 3.08047 5.99326 3.25302 5.99326H6.7229C6.89545 5.99326 7.06094 5.92471 7.18295 5.8027C7.30496 5.68069 7.37351 5.51521 7.37351 5.34266C7.37351 5.17011 7.30496 5.00462 7.18295 4.88261C7.06094 4.7606 6.89545 4.69205 6.7229 4.69205H3.25302Z"
                        fill={color}/>
              </g>
              <defs>
                  <clipPath id="clip0_5268_1561">
                      <rect width={size} height={size} fill="white"/>
                  </clipPath>
              </defs>
          </svg>

      </>
  );
};
