import React, { useState } from 'react';
import InputField from '../Form/InputField';
import { usePassword } from '../../Services/hooks/password';
import { useSelector } from 'react-redux';
import { RootState } from '../../Storeredux/reducers/rootReducer';
import { userData } from '../../Storeredux/constants/actionTypes';
import CustomButton from '../Form/CustomButton';
import { areAllKeysFilled } from '../../Utils/formValidator';
import { useNavigate } from 'react-router-dom';

function ChangePassword() {
  const UserState = useSelector((state: RootState) => state.user as userData);
  const { user } = UserState;
  const navigate = useNavigate();
  const { changePassword, isLoading } = usePassword();
  const [data, setData] = useState({
    current_password: '',
    password: '',
    password_confirmation: '',
  });
  const [error, setError] = useState('');
  const isFormComplete = areAllKeysFilled(data);

  const onChange = (e: any, fieldKey: string) => {
    console.log('next', e.target.value, fieldKey);
    const value = e.target.value;
    setData({ ...data, [fieldKey]: e.target.value });
    if (fieldKey === 'password_confirmation') {
      if (value !== data.password) {
        setError('Passwords do not match');
      } else {
        setError('');
      }
    }
  };

  const submit = async () => {
    if (!error) {
      const response = await changePassword(user.id, data);
      console.log(response);
      if (response) {
        navigate('/app/properties');
      }
    }
  };

  return (
    <div className='p-10 flex flex-col gap-10'>
      <div className='flex w-full gap-5'>
        <InputField name='Old Password' fieldKey={'current_password'} type='password' value={data.current_password} onChange={onChange} className='w-full'/>
      </div>
      <div className='flex w-full gap-5'>
        <InputField name='New Password' fieldKey={'password'} type='password' value={data.password} onChange={onChange}  className='w-full'/>
      </div>
      <div className=' w-full gap-5'>
        <InputField name='Confirm Pawword' type='password'  fieldKey={'password_confirmation'} value={data.password_confirmation} onChange={onChange}  className='w-full'/>
          {error && (
              <p className="text-red-500 text-sm">{error}</p>
          )}
      </div>
      <div className='flex w-full gap-5'>
        {/*<button onClick={submit} className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'>*/}
        {/*            Save Changes*/}
        {/*</button>*/}
          <CustomButton
              isLoading={isLoading}
              disabled={!isFormComplete}
              children={'Save Changes'}
              onClick={submit}
              className='bg-custom-midnightgreen text-white py-2 px-12 font-semibold rounded-custom border-0'
          />
      </div>
    </div>
  );
}

export default ChangePassword;
