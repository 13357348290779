import { useState } from 'react';
import { postChangePassword, postResetPassword, postVerifyPasswordOtp } from '../services/userService';
import { ChangePassword, ResetPassword, VerifyPasswordOtp } from '../constant/serviceType';
import { useToast } from '../../Store/NotificationContext';
import { useNavigate } from 'react-router-dom';

export const usePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const changePassword = async (id: string, payload: ChangePassword) => {
    try {
      setIsLoading(true);
      const response = await postChangePassword(id, payload);
      console.log(response);
      if (response) {
        setIsLoading(false);
        showToast('Success', response.data.message, 'success');
      }
      return response;
    } catch (e: any) {
      setIsLoading(false);
      showToast('Error', e.response.data.message, 'error');
      console.log(e);
    }
  };

  const verifyPasswordOtp = async (payload: VerifyPasswordOtp) => {
    try {
      setIsLoading(true);
      const response = await postVerifyPasswordOtp( payload);
      console.log(response.data.data);
      if (response) {
        setIsLoading(false);
        showToast('Success', response.data.message, 'success');
      }
      return response;
    } catch (e: any) {
      setIsLoading(false);
      showToast('Error', e.response.data.message, 'error');
      console.log(e);
    }
  };

  const resetPassword = async (payload: ResetPassword) => {
    try {
      setIsLoading(true);
      const response = await postResetPassword( payload);
      console.log(response);
      if (response) {
        setIsLoading(false);
        showToast('Success', response.data.essage || response.data.message, 'success');
        navigate('/login');
      }
      return response;
    } catch (e: any) {
      setIsLoading(false);
      showToast('Error', e.response.data.message, 'error');
      console.log(e);
    }
  };

  return {
    changePassword,
    isLoading,
    verifyPasswordOtp,
    resetPassword,
  };
};
